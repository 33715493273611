import React, { useState, useEffect, useRef } from 'react'
import Icon from '../Icon/Icon.js'
import Text from '../Text/Text.js'

const SnackbarItem = ({ item: { message, iconName, type, lifetime }, onClose }) => {
  const [expired, setExpired] = useState(false)
  const expiresAt = useRef(null)

  const classes = ['SnackbarItem']
  switch (type) {
    case 'info': {
      classes.push('SnackbarItem-info')
      iconName = iconName || null
      break
    }
    case 'error': {
      classes.push('SnackbarItem-error')
      iconName = iconName || 'danger'
      break
    }
    default:
  }

  if (expired) {
    classes.push('SnackbarItem-expired')
  }

  useEffect(() => {
    if (!lifetime) return
    if (!expiresAt.current) {
      expiresAt.current = new Date().getTime() + lifetime
    }
    const now = new Date().getTime()
    const delay = Math.max(0, expiresAt.current - now)
    const fadeTimeout = setTimeout(() => {
      setExpired(true)
    }, delay)
    const closeTimeout = setTimeout(() => {
      onClose()
    }, delay + 500)

    return () => {
      clearTimeout(fadeTimeout)
      clearTimeout(closeTimeout)
    }
  }, [lifetime, onClose])

  return (
    <div className={classes.join(' ')}>
      { iconName && <Icon name={iconName} width={24} height={24} /> }
      <div className="SnackbarItem-message"><Text size={14} weight={600}>{message}</Text></div>
      <button className="SnackbarItem-close" onClick={onClose}><Icon name="close" width={8} height={8} /></button>
    </div>
  )
}

export default SnackbarItem
