import React from 'react'
import { Provider } from 'react-redux'
import store from '../../redux/store.js'
import Content from '../Content/Content.js'
import CenterContent from '../CenterContent/CenterContent.js'
import Navigation from '../Navigation/Navigation.js'
import './App.scss'
import Snackbar from '../Snackbar/Snackbar.js'
import ChangeClients from '../ChangeClients/ChangeClients.js'

function App ({ isLoggedIn, children }) {
  return (
    <Provider store={store}>
      <div className="App-root">
        { isLoggedIn
          ? <div className="App-body">
            <Navigation />
            <ChangeClients />
            <Snackbar direction="column-reverse" position={{ left: 96 + 24, bottom: 24 }} />
            <Content>
              {children}
            </Content>
          </div>
          : <CenterContent>
            {children}
          </CenterContent>
        }
      </div>
    </Provider>
  )
}

export default App
