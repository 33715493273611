import React from 'react'
import './Text.scss'
import PropTypes from 'prop-types'

const Text = ({ size, weight, color, children, className = '' }) => {
  return (
    <p
      className={[className, 'Text-root'].join(' ')}
      style={{
        fontSize: size,
        fontWeight: weight,
        color: color && `var(--${color})`
      }}
    >{children}</p>
  )
}

Text.propTypes = {
  size: PropTypes.number,
  weight: PropTypes.number,
  color: PropTypes.oneOf([
    'primary-super-light',
    'primary-light',
    'primary-medium-light',
    'primary',
    'secondary-super-light',
    'secondary-light',
    'secondary-medium-light',
    'secondary',
    'white',
    'grey-super-light',
    'grey-light',
    'grey-medium-light',
    'grey-medium',
    'grey-medium-dark',
    'grey-dark',
    'grey-super-dark',
    'error',
    'warning',
    'success',
    'shadow',
    'text'
  ]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element])
}

export default Text
