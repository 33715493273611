import { useState, useEffect } from 'react'
import Parse from 'parse'
const Website = Parse.Object.extend('Website')

async function _getWebsite (id) {
  let website = new Website()
  website.id = id

  if (!id) return website

  if (await website.exists()) {
    await website.fetch()
  } else {
    website = null
  }

  return website
}

function useWebsite (id) {
  const [websites, setWebsites] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    let unmounting = false
    async function getWebsite () {
      setLoading(true)
      let website, error
      try {
        website = await _getWebsite(id)
      } catch (err) {
        error = err
      }
      if (unmounting) return
      if (!error) {
        setWebsites(website)
      } else {
        setError(error)
      }
      setLoading(false)
    }
    getWebsite()
    return () => {
      unmounting = true
    }
  }, [id])

  return [loading, websites, error]
}

export default useWebsite
