import React, { useState, useEffect, useMemo } from 'react'
import './Select.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'
import Text from '../Text/Text.js'

const Select = ({ label, options, value, onChange }) => {
  const [selectedValue, setSelectedValue] = useState({})
  const [activeOption, setActiveOption] = useState(null)
  const [open, setOpen] = useState(false)
  const id = useMemo(() => {
    return 'Select-id-' + label.split('').map(a => a.charCodeAt()).join('')
  }, [label])

  useEffect(() => {
    for (let i = 0, l = options.length; i < l; i++) {
      const option = options[i]
      if (option.value === value) {
        setSelectedValue(option)
        setActiveOption(i)
      }
      continue
    }
  }, [options, value])

  useEffect(() => {
    if (!open) return

    function handleWindowClick (e) {
      if (!e.target.closest('#' + id)) {
        setOpen(false)
      }
    }

    window.addEventListener('click', handleWindowClick)

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [open, id])

  useEffect(() => {
    if (!open) return

    function handleKeyDown (e) {
      const code = e.code
      const length = options.length
      if (code === 'ArrowDown') {
        setActiveOption((activeOption + 1) % length)
      } else if (code === 'ArrowUp') {
        setActiveOption(((activeOption - 1) + length) % length)
      } else if (code === 'Space' || code === 'Enter') {
        setOpen(false)
        onChange(options[activeOption].value)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [open, options, activeOption, onChange])

  function handleOptionClick (option) {
    return e => {
      e.stopPropagation()
      setOpen(false)
      onChange(option.value)
    }
  }

  function handleKeyDown (e) {
    // copy default behaviour of html select
    const code = e.nativeEvent.code
    if (code === 'Space' || code === 'Enter') { // open select when pressing space or enter
      e.preventDefault() // prevent page scroll which happens when pressing space
      setOpen(true)
    } else if ((code === 'Tab' || code.indexOf('Arrow') === 0) && open) { // ignore tab when select is open
      e.preventDefault()
    } else if (code === 'Escape') { // close select when pressing escape
      setOpen(false)
    }
  }

  function handleOpenClick (e) {
    setOpen(!open)
  }

  function handleMouseEnter (i) {
    return e => {
      setActiveOption(i)
    }
  }

  const rootClasses = ['Select']
  if (open) rootClasses.push('Select-open')

  const optionsClasses = ['Select-options']
  if (open) optionsClasses.push('Select-options-open')

  return (
    <div id={id} className={rootClasses.join(' ')} onClick={handleOpenClick} tabIndex="0" onKeyDown={handleKeyDown}>
      <label><Text color="grey-medium-dark" size={12} weight={400}>{label}</Text></label>
      <div className="Select-current"><Text size={12} weight={400}>{selectedValue.label}</Text></div>
      <div className="Select-icon"><Icon name="arrowDown" width={7} height={7} fill="var(--grey-dark)" /></div>
      <div className={optionsClasses.join(' ')}>
        {options.map((option, i) => {
          const optionClasses = ['Select-option']
          if (option === selectedValue) optionClasses.push('Select-option-active')
          if (i === activeOption) optionClasses.push('Select-option-hover')
          return (
            <div
              className={optionClasses.join(' ')}
              key={option.value}
              onClick={handleOptionClick(option)}
              onMouseEnter={handleMouseEnter(i)}
            ><Text size={12}>{option.label}</Text></div>
          )
        })}
      </div>
    </div>
  )
}

Select.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  )
}

export default Select
