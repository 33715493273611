import React from 'react'
import './Pagination.scss'
import Icon from '../Icon/Icon.js'
import PropTypes from 'prop-types'

const Pagination = ({ total, itemsPerPage, page, onChange }) => {
  const totalPages = Math.ceil(total / itemsPerPage)

  function setPage (newPage) {
    return e =>
      onChange(newPage)
  }

  const pageButtons = []
  const offset = page < 3
    ? Math.max(0, page - 3)
    : Math.min(Math.max(0, totalPages - 5), page - 3)
  const amountOfButtons = (totalPages < 5 ? totalPages : 5) - 1
  let x = 0
  do {
    const num = x + offset + 1
    pageButtons.push(<PageButton key={num} active={num === page} num={num} onClick={setPage(num)} />)
  } while (x++ < amountOfButtons)

  return (
    <div className="Pagination">
      { page !== 1
        ? <>
          <button className="Pagination-button" onClick={setPage(1)}><Icon width={7} height={7} name="doubleArrowLeft" /></button>
          <button className="Pagination-button" onClick={setPage(page - 1)}><Icon width={7} height={7} name="arrowLeft" /></button>
        </>
        : <div className="Pagination-hidden-buttons"></div>
      }
      { pageButtons }
      { page !== totalPages
        ? <>
          <button className="Pagination-button" onClick={setPage(page + 1)}><Icon width={7} height={7} name="arrowRight" /></button>
          <button className="Pagination-button" onClick={setPage(totalPages)}><Icon width={7} height={7} name="doubleArrowRight" /></button>
        </>
        : <div className="Pagination-hidden-buttons"></div>
      }
    </div>
  )
}

Pagination.propTypes = {
  total: PropTypes.number,
  itemsPerPage: PropTypes.number,
  page: PropTypes.number,
  onChange: PropTypes.func
}

const PageButton = ({ num, onClick, active }) => {
  const classes = ['Pagination-button']
  if (active) classes.push('Pagination-button-active')
  return (
    <button disabled={active} onClick={onClick} className={classes.join(' ')}>{num}</button>
  )
}

export default Pagination
