
function leadingZero (num) {
  return num < 10 ? '0' + num : num
}

function formatFullDate (date) {
  if (!date) return ''
  if (typeof date !== 'object') date = new Date(date)
  const formatted = [
    leadingZero(date.getDate()),
    leadingZero(date.getMonth() + 1),
    date.getFullYear()
  ].join('/')
  return formatted
}

export default formatFullDate
