const intervalOptions = [{
  value: 'monthly',
  label: 'Monthly'
}, {
  value: 'quarterly',
  label: 'Quarterly'
}, {
  value: 'half-yearly',
  label: 'Half-yearly'
}, {
  value: 'yearly',
  label: 'Yearly'
}]

export default intervalOptions
