import { useRef, useEffect } from 'react'
import { createSnackbarError } from '../components/Snackbar/Snackbar.js'

function useErrorMessage (errors) {
  const previousErrors = useRef([])
  useEffect(() => {
    const previousErr = previousErrors.current
    const newErrors = errors.reduce((accumulator, currentValue, index) => {
      if (currentValue && !previousErr[index]) {
        return [...accumulator, currentValue]
      }
      return accumulator
    }, [])
    previousErrors.current = errors

    if (newErrors.length) {
      newErrors.forEach(error => {
        createSnackbarError({ error })
      })
    }
  }, [errors])
}

export default useErrorMessage
