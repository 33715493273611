function sortParseObjects (sortStatus, items) {
  let sortBy, sortDirection
  if (sortStatus[0] === '-') {
    sortBy = sortStatus.slice(1)
    sortDirection = 'down'
  } else {
    sortBy = sortStatus
    sortDirection = 'up'
  }
  const sortedItems = [...items].sort((a, b) => {
    let num = 0
    if (a.get(sortBy) < b.get(sortBy)) num = -1
    if (a.get(sortBy) > b.get(sortBy)) num = 1
    return num
  })
  if (sortDirection === 'down') {
    sortedItems.reverse()
  }
  return sortedItems
}

export default sortParseObjects
