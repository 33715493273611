import React, { useState } from 'react'
import './Invoices.scss'
import useInvoices from '../../hooks/useInvoices.js'
import formatFullDate from '../../utils/formatFullDate.js'
import Text from '../../components/Text/Text.js'
import Button from '../../components/Button/Button.js'
import Block from '../../components/Block/Block.js'
import Tag from '../../components/Tag/Tag.js'
import Table from '../../components/Table/Table.js'
import Pagination from '../../components/Pagination/Pagination.js'
import Select from '../../components/Select/Select.js'
import Loader from '../../components/Loader/Loader.js'
import usePageTitle from '../../hooks/usePageTitle.js'
import useErrorMessage from '../../hooks/useErrorMessage.js'
import useActiveClient from '../../hooks/useActiveClient.js'
import toEuro from '../../utils/toEuro.js'

const Invoices = ({ i }) => {
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState('10')
  const [filterDueInvoices, setFilterDueInvoices] = useState(true)
  const [activeClientObject] = useActiveClient()
  const queryFilters = []
  if (filterDueInvoices) {
    queryFilters.push(['equalTo', 'paid', false])
  }
  const [invoicesLoading, invoices, invoicesCount, dueInvoicesCount, invoicesError] = useInvoices({
    sortBy: '-sortDate',
    skip: ~~itemsPerPage * (page - 1),
    limit: ~~itemsPerPage,
    clientId: activeClientObject.id,
    queryFilters
  })
  usePageTitle('Invoices')

  useErrorMessage([invoicesError])

  const headers = [{
    id: 'number',
    name: 'Invoice number',
    flex: '1 0 200px'
  }, {
    id: 'status',
    name: 'Status',
    flex: '1 0 200px'
  }, {
    id: 'amount',
    name: 'Amount',
    flex: '1 0 100px'
  }, {
    id: 'dueAmount',
    name: 'Due',
    flex: '1 0 100px'
  }, {
    id: 'invoiceDate',
    name: 'Invoice date',
    flex: '1 0 100px'
  }, {
    id: 'dueDate',
    name: 'Due date',
    flex: '1 0 100px'
  }]

  const Row = ({ item }) => {
    const classes = ['Invoices-row']
    if (!item.get('paid') && new Date(item.get('due_on')).getTime() < new Date().getTime()) classes.push('Invoices-row-red')

    return (
      <div className={classes.join(' ')}>
        <div className="Invoices-row-number">
          <Text size={14} weight={700}>{item.get('invoice_number')}</Text>
        </div>
        <div className="Invoices-row-status">
          <Status item={item} />
        </div>
        <div className="Invoices-row-amount">
          <Amount name="tax_exclusive" total={item.get('total')}/>
        </div>
        <div className="Invoices-row-amount">
          <Amount name="due" total={item.get('total')}/>
        </div>
        <div className="Invoices-row-date">
          <Text size={14} weight={400}>{formatFullDate(item.get('invoice_date'))}</Text>
        </div>
        <div className="Invoices-row-expiration-date">
          <Text size={14} weight={400}>{formatFullDate(item.get('due_on'))}</Text>
        </div>
      </div>
    )
  }

  function handleItemsPerPageChange (value) {
    setPage(1)
    setItemsPerPage(value)
  }

  const availableItemsPerPageOptions = [{
    value: '10',
    label: '10'
  }, invoicesCount > 25 && {
    value: '25',
    label: '25'
  }, invoicesCount > 50 && {
    value: '50',
    label: '50'
  }].reduce((accumulator, currentValue) => currentValue ? [...accumulator, currentValue] : accumulator, [])

  const isLoading = invoicesLoading

  return (
    <div className="Invoices">
      <div className="Invoices-title">
        <Text color="secondary" size={20} weight={700}>Invoices</Text>
        { !isLoading && <Button buttonStyle={filterDueInvoices ? 'primary' : 'primary-off'} size={14} weight={700} onClick={(e) => { setFilterDueInvoices(!filterDueInvoices) }}>Filter unpaid invoices: {dueInvoicesCount}</Button> }
        { isLoading && <div className="Invoices-title-loader"><Loader /></div> }
        <div className="Invoices-title-fill"></div>
      </div>
      <Block>
        <Table
          items={invoices}
          headers={headers}
          Row={Row}
        />
        { invoicesCount > itemsPerPage &&
          <div className="Invoices-footer">
            <Text size={12} weight={400} color="grey-dark">{`${invoices.length} of ${invoicesCount} invoices`}</Text>
            { invoicesCount > 10 && <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              label="Items per page:"
              options={availableItemsPerPageOptions}
            /> }
            <div className="Invoices-footer-pagination">
              <Pagination
                total={invoicesCount}
                itemsPerPage={~~itemsPerPage}
                page={page}
                onChange={page => setPage(page)}
              />
            </div>
          </div>
        }
      </Block>
    </div>
  )
}

const Amount = ({ total, name }) => {
  let amount
  try {
    amount = total[name].amount
  } catch (error) {}
  return (
    <Text>{toEuro(amount || 0)}</Text>
  )
}

const Status = ({ item }) => {
  switch (true) {
    case item.get('paid'): return <Tag color="green" name="Paid" />
    case new Date(item.get('due_on')).getTime() < new Date().getTime(): return <Tag color="red" name="Overdue" />
    default: return <Tag color="orange" name="Due" />
  }
}

export default Invoices
