import React, { useState, useMemo } from 'react'
import Block from '../../components/Block/Block.js'
import Table from '../../components/Table/Table.js'
import Loader from '../../components/Loader/Loader.js'
import Text from '../../components/Text/Text.js'
import Icon from '../../components/Icon/Icon.js'
import ResellerIcon from '../../components/ResellerIcon/ResellerIcon.js'
import './Plugins.scss'
import toEuro from '../../utils/toEuro.js'
import useActiveClient from '../../hooks/useActiveClient.js'

const Plugins = ({ plugins, bundles, isLoading }) => {
  const [activeClientObject] = useActiveClient()
  const [pluginsSortStatus, setPluginsSortStatus] = useState('name')
  const [activeBundleRow, setActiveBundleRow] = useState(null)

  const groupedList = useMemo(() => {
    return [
      bundles.map(bundle => ({
        id: bundle.bundle.id,
        name: bundle.bundle.get('name') || '',
        price: bundle.bundle.get('priceToCharge') || 0,
        item: bundle,
        type: 'bundle'
      })),
      [...plugins].map(plugin => ({
        id: plugin.id,
        name: plugin.get('name') || '',
        price: plugin.get('priceToCharge') || 0,
        item: plugin,
        type: 'plugin'
      }))
    ].flat()
  }, [plugins, bundles])
  const costsSum = useMemo(() => {
    return groupedList.reduce((accumulator, currentValue) => accumulator + currentValue.price, 0)
  }, [groupedList])

  const isReseller = activeClientObject.reseller
  const totalPrice = (() => {
    if (isReseller) {
      return (<><del>{toEuro(costsSum || 0)}</del>{` => ${toEuro((costsSum - (costsSum * (activeClientObject.resellerDiscount || 0))) || 0)}`}</>)
    } else {
      return toEuro(costsSum || 0)
    }
  })()
  const totalPriceTitle = (() => {
    if (isReseller) {
      return 'Total reseller price (-25 %)'
    } else {
      return 'Total'
    }
  })()

  const pluginHeaders = [{
    id: 'name',
    name: 'Name',
    flex: '1 0 300px',
    sortName: 'name'
  }, {
    id: 'price',
    name: 'Price',
    flex: '1 0 60px',
    sortName: 'price',
    alignRight: true
  }, {
    id: 'link',
    name: '',
    flex: '1 0 40px'
  }]

  const sortedPlugins = useMemo(() => {
    const sorted = [...groupedList]
    const sortPluginsBy = pluginsSortStatus[0] === '-' ? pluginsSortStatus.slice(1) : pluginsSortStatus
    if (sortPluginsBy === 'price') {
      sorted.sort((a, b) => {
        let num = 0
        const aPrice = a.price
        const bPrice = b.price
        if (aPrice < bPrice) num = -1
        if (aPrice > bPrice) num = 1
        return num
      })
    } else {
      sorted.sort((a, b) => {
        let num = 0
        const aName = a.name.toLowerCase()
        const bName = b.name.toLowerCase()
        if (aName < bName) num = -1
        if (aName > bName) num = 1
        return num
      })
    }
    if (pluginsSortStatus[0] === '-') {
      sorted.reverse()
    }
    return sorted
  }, [pluginsSortStatus, groupedList])

  function handleRowClick (id) {
    return () => {
      if (activeBundleRow === id) {
        setActiveBundleRow(null)
      } else {
        setActiveBundleRow(id)
      }
    }
  }

  const TableRow = ({ item: { type, ...props } } = {}) => {
    if (type === 'plugin') {
      return <PluginRow plugin={props.item} />
    } else if (type === 'bundle') {
      return <BundleRow
        {...props.item}
        isActive={activeBundleRow === props.item.bundle.id}
        handleRowClick={handleRowClick(props.item.bundle.id)}
      />
    }
  }

  return (
    <Block>
      <div className="Website-plugins-title">
        <Text size={16} weight={700} color="secondary">Plugins</Text>
        { !isLoading && <Text color="grey-medium-dark" size={14} weight={700}>{sortedPlugins.length}</Text> }
        { isLoading && <div className="Website-plugins-title-loader"><Loader /></div> }
        <div className="Website-plugins-title-fill"></div>
      </div>
      <Table
        items={sortedPlugins}
        headers={pluginHeaders}
        Row={TableRow}
        sortStatus={pluginsSortStatus}
        setSortStatus={setPluginsSortStatus}
      />
      <div className="Website-plugins-total">
        <div className="Website-plugins-total-title"><Text size={14} color="grey-super-dark" weight={700}>{totalPriceTitle}</Text>{isReseller && <ResellerIcon />}</div>
        <div className="Website-plugins-total-price"><Text size={14} color="grey-super-dark" weight={700}>{totalPrice}</Text></div>
        <div className="Website-plugins-total-fill"></div>
      </div>
    </Block>
  )
}

export default Plugins

const PluginRow = ({ plugin }) => {
  const name = plugin.get('name')
  const price = toEuro(plugin.get('priceToCharge'))
  const url = plugin.get('pluginURL')
  return (
    <div className="Website-plugins-row">
      <div className="Website-plugins-row-name">
        <Text size={14} weight={700}>{name}</Text>
      </div>
      <div className="Website-plugins-row-price"><Text size={14} weight={400}>{price}</Text></div>
      <div className="Website-plugins-row-link"><Url url={url} /></div>
    </div>
  )
}

const Url = ({ url }) => {
  if (!url) return null
  return (
    <a href={url} target="_blank" rel="noopener noreferrer"><Text color="grey-dark" size={12}>More</Text><Icon fill="var(--grey-dark" height={8} width={8} name="external" /></a>
  )
}

const BundleRow = ({ bundle, plugins, isActive, handleRowClick }) => {
  const price = toEuro(bundle.get('priceToCharge') || 0)
  const classes = ['Website-plugins-bundle-row']
  if (isActive) classes.push('Website-plugins-bundle-row-active')
  return (
    <div className={classes.join(' ')}>
      <div className="Website-plugins-row" onClick={handleRowClick}>
        <div className="Website-plugins-row-name">
          <div className="Website-plugins-row-caret"><Icon name={isActive ? 'arrowUp' : 'arrowDown'} width={7} height={7} /></div>
          <Text size={14} weight={700}>{bundle.get('name')}</Text>
          <div className="Website-plugins-row-box"><Icon name="box" /></div>
        </div>
        <div className="Website-plugins-row-price"><Text size={14}>{price}</Text></div>
        <div className="Website-plugins-row-link"></div>
      </div>
      { isActive &&
        <div className="Website-plugins-bundle-plugins">
          {plugins.map(plugin => {
            const price = toEuro(plugin.get('priceToCharge'))
            return (
              <div key={plugin.id} className="Website-plugins-row">
                <div className="Website-plugins-row-name">
                  <Text size={14} weight={400}>{plugin.get('name')}</Text>
                </div>
                <div className="Website-plugins-row-price"><Text size={14} weight={400}>{price}</Text></div>
                <div className="Website-plugins-row-link"></div>
              </div>
            )
          })}
        </div>
      }
    </div>
  )
}
