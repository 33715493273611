import React, { useState } from 'react'
import './Popup.scss'

const Popup = ({ content, children }) => {
  const [popupVisible, setPopupVisible] = useState(false)

  function handleMouseEnter () {
    setPopupVisible(true)
  }

  function handleMouseLeave () {
    setPopupVisible(false)
  }

  return (
    <div className="Popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      { popupVisible &&
        <div className="Popup-box">
          {content}
        </div>
      }
    </div>
  )
}

export default Popup
