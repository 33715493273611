import Parse from 'parse'
import { reduxLogout } from '../redux/store.js'
import history from '../history.js'

async function logout () {
  try {
    window.localStorage.removeItem('activeClient')
    await Parse.User.logOut()
    reduxLogout()
    history.push('/login')
  } catch (error) {
    // TODO handle error
    console.error(error)
  }
}

export default logout
