import React from 'react'
import './Table.scss'
import Text from '../Text/Text.js'
import Icon from '../Icon/Icon.js'
import PropTypes from 'prop-types'

const Table = ({ getKey, items, sortStatus, setSortStatus, Row, headers }) => {
  return (
    <div className="Table">
      <div className="Table-header">
        { headers.map(header => Header({ ...header, setSortStatus, sortStatus })) }
      </div>
      <div className="Table-body">
        { items.map(item => <Row key={getKey ? getKey(item) : item.id} item={item} />) }
      </div>
    </div>
  )
}

Table.propTypes = {
  getKey: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ),
  sortStatus: PropTypes.string,
  setSortStatus: PropTypes.func,
  row: PropTypes.node,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      sortName: PropTypes.string,
      flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      alignRight: PropTypes.bool
    })
  )
}

const Header = ({ name, sortName, flex, alignRight, setSortStatus, sortStatus }) => {
  const classes = ['Table-head']
  if (sortName) classes.push('Table-head-sortable')
  let sortDirection
  const sortClasses = ['Table-head-sort']
  if (sortName && (!!~sortStatus.indexOf(sortName))) {
    sortDirection = ~sortStatus.indexOf('-') ? 'down' : 'up'
    sortClasses.push('Table-head-sort-active')
  }
  if (alignRight) {
    classes.push('Table-head-right')
  }

  function handleSort (e) {
    if (!sortName) return
    setSortStatus(sortStatus === sortName ? '-' + sortName : sortName)
  }

  return (
    <div key={name} style={{ flex }} className={classes.join(' ')} onClick={handleSort}>
      <Text size={14} weight={400}>{name}</Text>
      { sortName && <div className={sortClasses.join(' ')}>
        <Icon name={sortDirection === 'down' ? 'caretDown' : 'caretUp'} width={6} height={6} />
      </div> }
    </div>
  )
}

export default Table
