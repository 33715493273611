import { Suspense, lazy } from 'react'
import Login from './pages/Login/Login.js'
import Websites from './pages/Websites/Websites.js'
import Plugins from './pages/Plugins/Plugins.js'
import Domains from './pages/Domains/Domains.js'
import RecurringCosts from './pages/RecurringCosts/RecurringCosts.js'
import Invoices from './pages/Invoices/Invoices.js'
import Website from './pages/Website/Website.js'
import Profile from './pages/Profile/Profile.js'
import isDev from './utils/isDev.js'

const routes = [
  {
    path: '/',
    action: () => <Websites />
  },
  {
    path: '/login',
    action: () => <Login />
  },
  {
    path: '/plugins',
    action: () => <Plugins />
  },
  {
    path: '/domains',
    action: () => <Domains />
  },
  {
    path: '/reccosts',
    action: () => <RecurringCosts />
  },
  {
    path: '/invoices',
    action: () => <Invoices />
  },
  {
    path: '/website/:id',
    action: (context, params) => <Website id={params.id} />
  },
  {
    path: '/profile',
    action: () => <Profile />
  },
  isDev && {
    path: '/previews',
    action: () => {
      const Previews = lazy(() => import('./pages/Previews/Previews.js'))
      return (
        <Suspense fallback={<div>React.Suspense in action!</div>}>
          <Previews />
        </Suspense>
      )
    }
  }
]

export default routes
