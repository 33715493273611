import React from 'react'
import './Button.scss'
import PropTypes from 'prop-types'
import Text from '../Text/Text.js'

const Button = ({ buttonStyle = 'primary', stretch, children, ...props }) => {
  const classes = [
    'Button',
    `Button-${buttonStyle}`
  ]
  if (stretch) classes.push('Button-strech')
  return (
    <button className={classes.join(' ')} {...props}>
      { typeof children === 'string'
        ? <Text>{children}</Text>
        : children
      }
    </button>
  )
}

Button.propTypes = {
  buttonStyle: PropTypes.oneOf(['primary', 'secondary', 'secondary-text', 'primary-off']),
  type: PropTypes.string,
  stretch: PropTypes.bool,
  children: PropTypes.node
}

export default Button
