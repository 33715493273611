import { useState, useEffect } from 'react'
import Parse from 'parse'
const TeamleaderInvoice = Parse.Object.extend('TeamleaderInvoice')
const Client = Parse.Object.extend('Client')

async function _getInvoices ({ sortBy, skip, limit, countOnly, clientId, queryFilters } = {}) {
  let invoices = []
  let count = 0
  let dueCount = 0
  if (!clientId) return [invoices]

  const invoicesQuery = new Parse.Query(TeamleaderInvoice)
  invoicesQuery.notEqualTo('status', 'draft')
  if (queryFilters) {
    queryFilters.forEach(([name, ...params]) => {
      invoicesQuery[name](params[0], params[1])
    })
  }
  skip && invoicesQuery.skip(skip)
  limit && invoicesQuery.limit(limit)
  invoicesQuery.equalTo('client', new Client({ objectId: clientId }))
  if (sortBy) {
    if (sortBy[0] === '-') {
      invoicesQuery.descending(sortBy.slice(1))
    } else {
      invoicesQuery.ascending(sortBy)
    }
  }
  if (!countOnly) {
    invoices = await invoicesQuery.find()
  }
  count = await invoicesQuery.count()

  const dueInvoicesQuery = new Parse.Query(TeamleaderInvoice)
  dueInvoicesQuery.notEqualTo('status', 'draft')
  dueInvoicesQuery.equalTo('paid', false)
  dueInvoicesQuery.equalTo('client', new Client({ objectId: clientId }))
  dueCount = await dueInvoicesQuery.count()

  return [invoices, count, dueCount]
}

const defaultFilters = []

function useInvoices ({ sortBy, skip, limit, countOnly, clientId, queryFilters = defaultFilters } = {}) {
  const [invoices, setInvoices] = useState([])
  const [invoicesCount, setInvoicesCount] = useState(0)
  const [dueInvoicesCount, setDueInvoicesCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const queryFiltersString = JSON.stringify(queryFilters)

  useEffect(() => {
    let unmounting = false
    async function getDomains () {
      setLoading(true)
      let invoices, count, dueCount, error
      try {
        [invoices, count, dueCount] = await _getInvoices({ sortBy, skip, limit, countOnly, clientId, queryFilters: JSON.parse(queryFiltersString) })
      } catch (err) {
        error = err
      }
      if (unmounting) return
      if (!error) {
        setInvoices(invoices)
        setInvoicesCount(count)
        setDueInvoicesCount(dueCount)
      } else {
        setError(error)
      }
      setLoading(false)
    }
    getDomains()
    return () => {
      unmounting = true
    }
  }, [sortBy, skip, limit, countOnly, clientId, queryFiltersString])

  return [loading, invoices, invoicesCount, dueInvoicesCount, error]
}

export default useInvoices
