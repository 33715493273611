import React, { useState, useEffect } from 'react'
import './ChangeClients.scss'
import Icon from '../Icon/Icon.js'
import Text from '../Text/Text.js'
import history from '../../history.js'
import useUser from '../../hooks/useUser.js'
import useActiveClient from '../../hooks/useActiveClient.js'
import { useDispatch, useSelector } from 'react-redux'

const ChangeClients = React.memo(() => {
  const [user] = useUser()
  const [clients, setClients] = useState([])
  const [activeClientObject, setActiveClient] = useActiveClient()
  const { showclients: open } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const activeClient = clients.find(({ id }) => id === activeClientObject.id)

  useEffect(() => {
    let unmounting = false
    if (!user || !open) return
    async function getClients () {
      const clientsQuery = user.relation('clients').query()
      clientsQuery.ascending('sortClientName')
      const clients = await clientsQuery.find()
      if (!unmounting) {
        setClients(clients)
      }
    }
    getClients()
    return () => {
      unmounting = true
    }
  }, [user, open])

  // close when user clicks outside the modal or presses the escape key
  useEffect(() => {
    if (!open) return
    function handleWindowClick (e) {
      if (!e.target.closest('#ChangeClients-box')) {
        dispatch({ type: 'HIDE_CLIENTS' })
      }
    }
    function handleKeyDown (e) {
      const code = e.code
      if (code === 'Escape') {
        dispatch({ type: 'HIDE_CLIENTS' })
      }
    }
    window.addEventListener('click', handleWindowClick)
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('click', handleWindowClick)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [open, dispatch])

  if (!open) return null

  function handleClientClick (client) {
    return () => {
      if (history.location.pathname.startsWith('/website/')) {
        history.goBack()
      }
      setActiveClient({
        id: client.id,
        companyId: (client.get('teamleaderCompany') || {}).id,
        reseller: (client.get('reseller') || false),
        resellerDiscount: client.get('resellerDiscount')
      })
      dispatch({ type: 'HIDE_CLIENTS' })
    }
  }

  // TODO: if user is admin add search field
  // let isAdmin
  // try {
  //   isAdmin = new Set(user.get('roles') || []).has('admin')
  // } catch (error) {}

  return (
    <div className="ChangeClients">
      <div id="ChangeClients-box" className="ChangeClients-box">
        <div className="ChangeClients-close" onClick={() => dispatch({ type: 'HIDE_CLIENTS' })}><Icon width={10} height={10} fill="var(--grey-medium)" name="close" /></div>
        <div className="ChangeClients-title">
          <Text size={20} weight={700} color="secondary">Choose client to manage</Text>
        </div>
        <div className="ChangeClients-body">
          <div className="ChangeClients-body-text"><Text size={14} weight={400} color="grey-super-dark">{`Currently managing ${activeClient ? activeClient.get('clientName') : ''}`}</Text></div>
          <div>
            <div className="ChangeClients-body-text"><Text size={16} weight={700} color="secondary">Available clients</Text></div>
            <div className="ChangeClients-list">
              {clients.map(client => {
                return (
                  <div key={client.id} onClick={handleClientClick(client)} className="ChangeClients-row">
                    <Text size={14} weight={700} color="grey-super-dark">{client.get('clientName')}</Text>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default ChangeClients
