import React, { useState } from 'react'
import './Websites.scss'
import Text from '../../components/Text/Text.js'
import Block from '../../components/Block/Block.js'
import Table from '../../components/Table/Table.js'
import Tag from '../../components/Tag/Tag.js'
import Loader from '../../components/Loader/Loader.js'
import sortParseObjects from '../../utils/sortParseObjects.js'
import useWebsites from '../../hooks/useWebsites.js'
import getUrlWithAuth from '../../utils/getUrlWithAuth.js'
import history from '../../history.js'
import usePageTitle from '../../hooks/usePageTitle.js'
import useErrorMessage from '../../hooks/useErrorMessage.js'
import useActiveClient from '../../hooks/useActiveClient.js'

const Maintenance = ({ website }) => {
  if (!website.get('maintenance')) return <Text color="grey-super-dark" size={14} weight={400}>No</Text>
  const maintenanceHoursPerMonth = website.get('maintenanceHoursPerMonth') || 0
  const maintenanceIntervalInDays = website.get('maintenanceIntervalInDays') || 0
  return <Text color="grey-super-dark" size={14} weight={400}>{`${maintenanceHoursPerMonth}h${maintenanceHoursPerMonth !== 1 ? 'rs' : ''} / ${maintenanceIntervalInDays} day${maintenanceIntervalInDays !== 1 ? 's' : ''}`}</Text>
}

const Websites = () => {
  const [activeClientObject] = useActiveClient()
  const [sortStatus, setSortStatus] = useState('url')
  const [loadingWebsites, websites, websitesError] = useWebsites({ clientId: activeClientObject.id })
  const sortedWebsites = sortParseObjects(sortStatus, websites)
  usePageTitle('Websites')

  useErrorMessage([websitesError])

  function handleRowClick (item) {
    return e => history.push(`/website/${item.id}`)
  }

  const headers = [{
    id: 'url',
    name: 'URL',
    sortName: 'url',
    flex: '1 0 376px'
  }, {
    id: 'environments',
    name: 'Environments',
    flex: '1 0 200px'
  }, {
    id: 'maintenance',
    name: 'Maintenance',
    flex: '1 0 200px'
  }]

  const Row = ({ item }) => {
    return (
      <div className="Websites-row" onClick={handleRowClick(item)}>
        <div className="Websites-row-url">
          <Text size={14} weight={700}>{item.get('url')}</Text>
        </div>
        <div className="Websites-row-environments">
          <Tag color="red-light" name="Production" target={item.get('url')}></Tag>
          { item.get('stagingWebsite') && <Tag color="yellow-light" name="Staging" target={getUrlWithAuth(item.get('stagingWebsite'))}></Tag> }
        </div>
        <div className="Websites-row-maintenance">
          <Maintenance website={item} />
        </div>
      </div>
    )
  }

  const isLoading = loadingWebsites

  return (
    <div className="Websites">
      <div className="Websites-title">
        <Text color="secondary" size={20} weight={700}>Websites</Text>
        { !isLoading && <Text color="grey-medium-dark" size={14} weight={700}>{sortedWebsites.length}</Text> }
        { isLoading && <Loader /> }
      </div>
      <Block>
        <Table
          items={sortedWebsites}
          headers={headers}
          Row={Row}
          sortStatus={sortStatus}
          setSortStatus={setSortStatus}
        />
      </Block>
    </div>
  )
}

export default Websites
