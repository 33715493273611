import React from 'react'
import './RecurringCosts.scss'
import Text from '../../components/Text/Text.js'
import Credits from './Credits.js'
import Websites from './Websites.js'
import GeneralCosts from './GeneralCosts.js'
import usePageTitle from '../../hooks/usePageTitle.js'
import usePaymentInterval from '../../hooks/usePaymentInterval.js'
import Select from '../../components/Select/Select.js'
import intervalOptions from '../../utils/intervalOptions.js'

const RecurringCosts = () => {
  usePageTitle('Recurring costs')
  const [paymentInterval, setPaymentInterval] = usePaymentInterval()

  return (
    <div className="RecurringCosts">
      <div className="RecurringCosts-title">
        <Text size={20} weight={700} color="secondary">Recurring costs</Text>
        <div className="RecurringCosts-title-info">
          <Select
            value={paymentInterval}
            onChange={setPaymentInterval}
            label="Cost interval:"
            options={intervalOptions}
          />
        </div>
      </div>
      <div className="RecurringCosts-top">
        <Websites />
      </div>
      <div className="RecurringCosts-bottom">
        <div>
          <GeneralCosts />
        </div>
        <div>
          <Credits />
        </div>
      </div>
    </div>
  )
}

export default RecurringCosts
