import { useState, useEffect } from 'react'
import Parse from 'parse'
const Domain = Parse.Object.extend('Domain')
const Client = Parse.Object.extend('Client')

async function _getDomains ({ sortBy, clientId } = {}) {
  let domains = []
  if (!clientId) return domains

  const domainsQuery = new Parse.Query(Domain)
  domainsQuery.equalTo('client', new Client({ objectId: clientId }))
  domainsQuery.equalTo('deleted', false)
  domainsQuery.include('price')
  domainsQuery.include('Price')
  if (sortBy) {
    if (sortBy[0] === '-') {
      domainsQuery.descending(sortBy.slice(1))
    } else {
      domainsQuery.ascending(sortBy)
    }
  }
  domains = await domainsQuery.find()

  return domains
}

function useDomains ({ sortBy, clientId } = {}) {
  const [domains, setDomains] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    let unmounting = false
    async function getDomains () {
      setLoading(true)
      let domains, error
      try {
        domains = await _getDomains({ sortBy, clientId })
      } catch (err) {
        error = err
      }
      if (unmounting) return
      if (!error) {
        setDomains(domains)
      } else {
        setError(error)
      }
      setLoading(false)
    }
    getDomains()
    return () => {
      unmounting = true
    }
  }, [sortBy, clientId])

  return [loading, domains, error]
}

export default useDomains
