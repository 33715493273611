import { useState, useEffect } from 'react'
import Parse from 'parse'
const ShortPixel = Parse.Object.extend('ShortPixel')
const Client = Parse.Object.extend('Client')

async function _getShortPixels ({ clientId } = {}) {
  let shortPixels = []
  if (!clientId) return shortPixels

  const shortPixelsQuery = new Parse.Query(ShortPixel)
  shortPixelsQuery.equalTo('client', new Client({ objectId: clientId }))
  shortPixelsQuery.notEqualTo('notBillable', true)
  shortPixelsQuery.ascending('name')
  shortPixels = await shortPixelsQuery.find()

  return shortPixels
}

function useShortPixels ({ clientId } = {}) {
  const [shortPixels, setShortPixels] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    let unmounting = false
    async function getShortPixels () {
      setLoading(true)
      let shortPixels, error
      try {
        shortPixels = await _getShortPixels({ clientId })
      } catch (err) {
        error = err
      }
      if (unmounting) return
      if (!error) {
        setShortPixels(shortPixels)
      } else {
        setError(error)
      }
      setLoading(false)
    }
    getShortPixels()
    return () => {
      unmounting = true
    }
  }, [clientId])

  return [loading, shortPixels, error]
}

export default useShortPixels
