import UniversalRouter from 'universal-router'
import routes from './routes.js'
import history from './history.js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App/App.js'
import NotFound from './pages/NotFound/NotFound.js'
import ErrorPage from './pages/ErrorPage/ErrorPage.js'
import Parse from 'parse'
import store from './redux/store.js'

const root = document.getElementById('root')

const options = {
  errorHandler (error, context) {
    console.error(error)
    console.info(context)
    if (error.status === 404) {
      return <NotFound />
    } else {
      return <ErrorPage />
    }
  }
}
const router = new UniversalRouter(routes, options)

function render (location) {
  const destination = location.pathname

  const currentUser = Parse.User.current()
  const noAuthDestinations = new Set(['/login'])
  if (!currentUser && !noAuthDestinations.has(destination)) {
    return history.push('/login')
  } else if (currentUser && noAuthDestinations.has(destination)) {
    return history.push('/')
  }

  const isLoggedIn = !noAuthDestinations.has(destination)
  if (isLoggedIn && !window.localStorage.getItem('activeClient')) {
    store.dispatch({ type: 'SHOW_CLIENTS' })
  }

  router.resolve({
    pathname: destination,
    context: {
      user: currentUser
    }
  }).then(component => {
    if (component.redirect) {
      history.push(component.redirect)
    } else {
      ReactDOM.render(
        <App isLoggedIn={isLoggedIn}>{component}</App>,
        root
      )
    }
  })
}

// Listen for changes to the current location.
// const unlisten =
history.listen(({ location, action }) => {
  render(location)
})

// To stop listening, call the function returned from listen().
// unlisten()

// Render the app for the first time
async function initialize () {
  render(history.location)
}

initialize()
