import { createStore, combineReducers } from 'redux'
import userReducer from './user/reducer.js'

const appReducer = combineReducers({
  user: userReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }
  return appReducer(state, action)
}

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

function reduxLogout () {
  store.dispatch({
    type: 'USER_LOGOUT'
  })
}

export default store
export { reduxLogout }
