import React, { useMemo } from 'react'
import './Navigation.scss'
import history from '../../history.js'
import { version } from '../../../package.json'
import Icon from '../Icon/Icon.js'
import Text from '../Text/Text.js'
import Button from '../Button/Button.js'
import Tag from '../Tag/Tag.js'
import ResellerIcon from '../ResellerIcon/ResellerIcon.js'
import logout from '../../utils/logout.js'
import useInvoices from '../../hooks/useInvoices.js'
import useErrorMessage from '../../hooks/useErrorMessage.js'
import useActiveClient from '../../hooks/useActiveClient.js'
import useUser from '../../hooks/useUser.js'

const Navigation = ({ page }) => {
  function handleLogOut (e) {
    logout()
  }

  return (
    <nav className="Navigation-root shadow-8">
      <div className="Navigation-logo">
        <img src="/logo192.png" alt="appsaloon" />
      </div>
      <Link to="/" name="Websites" iconName="domain" additionalActivePages={['/website']} />
      {/* <Link to="/plugins" name="Plugins" iconName="plugin" /> */}
      <Link to="/domains" name="Domains" iconName="be" />
      <Link to="/reccosts" name="Recurring costs" iconName="costs" />
      <LinkWithDueInvoiceCount>
        <Link to="/invoices" name="Invoices" iconName="bill" />
      </LinkWithDueInvoiceCount>
      <div className="Navigation-fill"></div>
      <div><Button stretch onClick={handleLogOut} buttonStyle="secondary">Log out</Button></div>
      <User isActive={history.location.pathname === '/profile'} />
      <div className="Navigation-version"><Text size={10} color="grey-dark">{`v${version}`}</Text></div>
    </nav>
  )
}

const Link = ({ to, name, iconName, additionalActivePages }) => {
  function handleClick (e) {
    e.preventDefault()
    history.push(to)
  }

  const classes = [
    'Navigation-link'
  ]

  const pathname = history.location.pathname
  const isActive = to === pathname || (additionalActivePages && additionalActivePages.some(page => pathname.indexOf(page) === 0))
  if (isActive) {
    classes.push('Navigation-active')
  }

  return (
    <a className={classes.join(' ')} href={to} onClick={handleClick}>
      <Icon name={iconName} width={28} height={28} />
      <Text weight={700} size={13}>{name}</Text>
    </a>
  )
}

const LinkWithDueInvoiceCount = ({ children }) => {
  const queryFilters = useMemo(() => [['equalTo', 'paid', false]], [])
  const [, , invoicesCount, error] = useInvoices({
    countOnly: true,
    queryFilters
  })

  useErrorMessage([error])

  return (
    <div className="Navigation-tag-container">
      { !!invoicesCount && <div className="Navigation-tag"><Tag circle={true} size="small" color="blue"><Text size={14} weight={700} color="white">{invoicesCount}</Text></Tag></div> }
      {children}
    </div>
  )
}

const User = React.memo(({ isActive }) => {
  const [user] = useUser()
  const [activeClientObject] = useActiveClient()

  const isReseller = activeClientObject.reseller

  function handleNameClick () {
    history.push('/profile')
  }

  return (
    <div className={['Navigation-user', isActive ? 'Navigation-user-active' : ''].join(' ')}>
      <button className="Navigation-user-button" onClick={handleNameClick}>
        <div className="Navigation-icons">
          <Icon fill="var(--grey-medium)" name="person" />
          { isReseller && <ResellerIcon /> }
        </div>
        <Text className="Navigation-user-name" size={10}>{user.get('username' || '')}</Text>
      </button>
    </div>
  )
})

export default Navigation
