import React, { useState, useEffect, useMemo } from 'react'
import './Website.scss'
import Text from '../../components/Text/Text.js'
import useWebsite from '../../hooks/useWebsite.js'
import usePageTitle from '../../hooks/usePageTitle.js'
import Plugins from './Plugins.js'
import Costs from './Costs.js'
import Environments from './Environments.js'
import Maintenance from './Maintenance.js'
import useErrorMessage from '../../hooks/useErrorMessage.js'
import useCosts from '../../hooks/useCosts.js'
import Select from '../../components/Select/Select.js'
import usePaymentInterval from '../../hooks/usePaymentInterval.js'
import intervalOptions from '../../utils/intervalOptions.js'

const Website = ({ id }) => {
  const [loadingWebsite, website, websiteError] = useWebsite(id)
  const [plugins, setPlugins] = useState(new Set())
  const [bundles, setBundles] = useState([])
  const [bundledPlugins, setBundledPlugins] = useState(new Set())
  const [loadingPlugins, setLoadingPlugins] = useState(false)
  const [loadingBundles, setLoadingBundles] = useState(false)
  const [loadingCosts, costs, costsError] = useCosts({ websiteId: id })
  const [paymentInterval, setPaymentInterval] = usePaymentInterval()
  usePageTitle(`Website ${website ? website.get('url') || '' : ''}`)

  useErrorMessage([websiteError, costsError])

  useEffect(() => {
    let unmounting = false
    if (!website) return
    async function getPlugins () {
      setLoadingPlugins(true)
      let premiumPlugins = []
      try {
        const activePlugins = await website.get('activePlugins').query().find()
        premiumPlugins = activePlugins.filter(plugin => plugin.get('isPremium'))
      } catch (error) {}
      if (unmounting) return
      setPlugins(new Set(premiumPlugins))
      setLoadingPlugins(false)
    }
    getPlugins()
    return () => {
      unmounting = true
    }
  }, [website])

  useEffect(() => {
    let unmounting = false
    if (!website) return
    async function getBundles () {
      setLoadingBundles(true)
      const bundles = await website.get('wordPressPluginBundles').query().find()
      const pluginPromises = bundles.map(bundle => bundle.get('WordPressPlugins').query().find())
      const pluginsPerBundle = await Promise.all(pluginPromises)
      if (unmounting) return
      const mappedPlugins = new Map([...plugins].map(plugin => [plugin.id, plugin]))
      const groupedBundles = bundles.map((bundle, i) => {
        const myPlugins = pluginsPerBundle[i]
        const matchedPlugins = []
        myPlugins.forEach(({ id }) => {
          const match = mappedPlugins.get(id)
          if (match) matchedPlugins.push(match)
        })
        return { bundle, plugins: matchedPlugins }
      }).filter(({ plugins }) => plugins.length)
      const allBundledPlugins = pluginsPerBundle.reduce((accumulator, currentValue) => [...accumulator, ...currentValue.map(plugin => plugin.id)], [])
      setBundles(groupedBundles)
      setBundledPlugins(new Set(allBundledPlugins))
      setLoadingBundles(false)
    }
    getBundles()
    return () => {
      unmounting = true
    }
  }, [website, plugins])

  const pluginsNotInBundles = useMemo(() => {
    return new Set([...plugins].filter(plugin => !bundledPlugins.has(plugin.id)))
  }, [plugins, bundledPlugins])

  const isLoading = loadingWebsite || loadingPlugins || loadingBundles || loadingCosts

  return (
    <div className="Website">
      <div className="Website-title">
        <Text color="secondary" size={20} weight={700}>{`Website ${website ? (website.get('url') || '') : ''}`}</Text>
        <div className="Website-title-info">
          <Select
            value={paymentInterval}
            onChange={setPaymentInterval}
            label="Cost interval:"
            options={intervalOptions}
          />
        </div>
      </div>
      <div className="Website-blocks">
        <div>
          <Plugins plugins={pluginsNotInBundles} bundles={bundles} isLoading={isLoading} />
        </div>
        <div>
          <Environments website={website} isLoading={loadingWebsite} />
          {website && website.get('maintenance')
            ? <Maintenance website={website} isLoading={loadingWebsite} />
            : null}
          <Costs costs={costs} isLoading={loadingCosts} />
        </div>
      </div>
    </div>
  )
}

export default Website
