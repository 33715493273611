import React, { useState, useEffect, useMemo } from 'react'
import './Domains.scss'
import Text from '../../components/Text/Text.js'
import Block from '../../components/Block/Block.js'
import Table from '../../components/Table/Table.js'
import Loader from '../../components/Loader/Loader.js'
import Select from '../../components/Select/Select.js'
import Icon from '../../components/Icon/Icon.js'
import useDomains from '../../hooks/useDomains.js'
import usePageTitle from '../../hooks/usePageTitle.js'
import useErrorMessage from '../../hooks/useErrorMessage.js'
import usePaymentInterval from '../../hooks/usePaymentInterval.js'
import useActiveClient from '../../hooks/useActiveClient.js'
import formatFullDate from '../../utils/formatFullDate.js'
import getCostAs from '../../utils/getCostAs.js'
import intervalOptions from '../../utils/intervalOptions.js'
import toEuro from '../../utils/toEuro.js'

function getTotalCostString (interval) {
  const match = intervalOptions.find(({ value }) => interval === value) || {}
  const translated = (match.label || '').toLowerCase()
  return `Total ${translated} cost`
}

const Price = ({ domain, interval }) => {
  const [activeClientObject] = useActiveClient()
  const [price, setPrice] = useState(null)

  useEffect(() => {
    if (!domain) return
    const prices = domain.get('price') || domain.get('Price')
    if (!prices) return
    function getDomainPrice () {
      const reseller = activeClientObject.reseller
      setPrice(prices.get(reseller ? 'resellerPrice' : 'customerPrice'))
    }
    getDomainPrice()
  }, [activeClientObject.reseller, domain])

  const convertedPrice = getCostAs({
    price: price || 0,
    interval: 'yearly'
  }, interval)

  return <Text size={14} weight={400}>{toEuro(convertedPrice)}</Text>
}

const Domains = () => {
  const [sortStatus, setSortStatus] = useState('domain')
  const [activeClientObject] = useActiveClient()
  const [loadingDomains, domains, domainsError] = useDomains({ sortBy: sortStatus, clientId: activeClientObject.id })
  const [paymentInterval, setPaymentInterval] = usePaymentInterval()
  usePageTitle('Domains')

  useErrorMessage([domainsError])

  const sumOfPrices = useMemo(() => {
    return domains.reduce((accumulator, currentValue) => {
      try {
        const reseller = activeClientObject.reseller
        const prices = currentValue.get('price') || currentValue.get('Price')
        return accumulator + (prices.get(reseller ? 'resellerPrice' : 'customerPrice') || 0)
      } catch (error) {
        return accumulator
      }
    }, 0)
  }, [activeClientObject.reseller, domains])
  const convertedSumOfPrices = getCostAs({
    price: sumOfPrices || 0,
    interval: 'yearly'
  }, paymentInterval)

  const headers = [{
    id: 'domain',
    name: 'Domain',
    sortName: 'domain',
    flex: '1 0 376px'
  }, {
    id: 'yearly',
    name: `${(intervalOptions.find(({ value }) => paymentInterval === value) || {}).label || 'Yearly'} cost`,
    flex: '1 0 200px'
  }, {
    id: 'renewal',
    name: 'Renewal date',
    sortName: 'renewalDate',
    flex: '1 0 200px'
  }]

  const Row = ({ item }) => {
    const hasRedirect = item.get('redirect')
    const redirect = item.get('redirectObject')
    return (
      <div className="Domains-row">
        <div className="Domains-row-domain">
          <Text size={14} weight={700}>{item.get('domain')}</Text>
          {hasRedirect && <>
            <Icon name="redirect" fill="var(--orange)" />
            <Text size={14} weight={400}>{redirect ? redirect.host : ''}</Text></>
          }
        </div>
        <div className="Domains-row-yearly">
          <Price domain={item} interval={paymentInterval} />
        </div>
        <div className="Domains-row-renewal">
          <Text size={14} weight={400}>{formatFullDate(item.get('renewalDate'))}</Text>
        </div>
      </div>
    )
  }

  const isLoading = loadingDomains

  return (
    <div className="Domains">
      <div className="Domains-title">
        <Text color="secondary" size={20} weight={700}>Domains</Text>
        { !isLoading && <Text color="grey-medium-dark" size={14} weight={700}>{domains.length}</Text> }
        { isLoading && <Loader /> }
        <div className="Domains-title-info">
          <Select
            value={paymentInterval}
            onChange={setPaymentInterval}
            label="Cost interval:"
            options={intervalOptions}
          />
        </div>
      </div>
      <Block>
        <Table
          items={domains}
          headers={headers}
          Row={Row}
          sortStatus={sortStatus}
          setSortStatus={setSortStatus}
        />
        <div className="Domains-total">
          <div className="Domains-total-title"><Text size={14} color="grey-super-dark" weight={700}>{getTotalCostString(paymentInterval)}</Text></div>
          <div className="Domains-total-price"><Text size={14} color="grey-super-dark" weight={700}>{toEuro(convertedSumOfPrices || 0)}</Text></div>
          <div className="Domains-total-fill"></div>
        </div>
      </Block>
    </div>
  )
}

export default Domains
