import React, { useState, useEffect, useRef } from 'react'
import './Snackbar.scss'
import PropTypes from 'prop-types'
import SnackbarItem from './SnackbarItem.js'

let id = 0
function getId () {
  return id++
}

function createSnackbarItem ({ message, iconName, type = 'info', lifetime = 6000 }) {
  // message = string
  // iconName = string (has to be one of the available names listed in Icon.js)
  // type = oneof: ['info', 'error'] (default: 'info')
  // lifetime = number, time in ms (optional)
  const event = new window.CustomEvent('createSnackbarItem')
  event.details = { message, iconName, type, lifetime }
  window.dispatchEvent(event)
}

function createSnackbarError ({ error, type = 'error', lifetime = 6000 }) {
  const event = new window.CustomEvent('createSnackbarItem')
  event.details = {
    message: error.message || error.toString(),
    type,
    lifetime
  }
  window.dispatchEvent(event)
}

const Snackbar = ({ direction = 'column', position }) => {
  const [items, setItems] = useState([])

  const refItems = useRef([])

  useEffect(() => {
    function handleEvent (e) {
      const details = e.details
      const newItem = {
        id: getId(),
        message: details.message,
        type: details.type,
        lifetime: details.lifetime
      }
      const newItems = [...refItems.current, newItem]
      refItems.current = newItems
      setItems(newItems)
    }

    window.addEventListener('createSnackbarItem', handleEvent)
    return () => window.removeEventListener('createSnackbarItem', handleEvent)
  }, [items])

  const classes = [
    'Snackbar',
    `Snackbar-${direction}`
  ]

  let positionStyle
  if (position) {
    positionStyle = {
      top: position.top,
      bottom: position.bottom,
      left: position.left,
      right: position.right
    }
  }

  function handleCloseItem (item) {
    const myId = item.id
    return e => {
      const newItems = [...refItems.current].filter(item => item.id !== myId)
      refItems.current = newItems
      setItems(newItems)
    }
  }

  return (
    <div className={classes.join(' ')} style={positionStyle}>
      { items.map(item => {
        return <SnackbarItem key={item.id} item={item} onClose={handleCloseItem(item)} />
      })
      }
    </div>
  )
}

Snackbar.propTypes = {
  direction: PropTypes.oneOf(['column', 'column-reverse']),
  position: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })
}

export default Snackbar
export { createSnackbarItem, createSnackbarError }
