import React from 'react'
import './Maintenance.scss'
import Block from '../../components/Block/Block.js'
import Text from '../../components/Text/Text.js'
import Loader from '../../components/Loader/Loader.js'

const Maintenance = ({ website, isLoading }) => {
  const maintenanceHoursPerMonth = website.get('maintenanceHoursPerMonth') || 0
  const maintenanceIntervalInDays = website.get('maintenanceIntervalInDays') || 0
  return (
    <Block>
      <div className="Website-maintenance-title">
        <Text size={16} weight={700} color="secondary">Maintenance</Text>
        { isLoading && <Loader /> }
      </div>
      <div className="Website-maintenance-tags">
        <Text size={16} weight={400} color="grey-super-dark">{`This project is maintained and gets updates for ${maintenanceHoursPerMonth} hour${maintenanceHoursPerMonth !== 1 ? 's' : ''} each ${maintenanceIntervalInDays} day${maintenanceIntervalInDays !== 1 ? 's' : ''}`}</Text>
      </div>
    </Block>
  )
}

export default Maintenance
