import React from 'react'
import './Block.scss'
import PropTypes from 'prop-types'

const Block = ({ className, children }) => <div className={`${className || ''} Block`.trim()}>{children}</div>

Block.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default Block
