import React, { useState } from 'react'
import './Input.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'
import Text from '../Text/Text.js'

const Input = ({ iconName, type, ...props }) => {
  if (type === 'password') return <PasswordInput {...{ iconName, type, ...props }}/>

  return (
    <div className="Input">
      { iconName && <div className="Input-icon"><Icon width={16} height={16} fill="var(--grey-medium)" name={iconName} /></div> }
      <input type={type} {...props} />
    </div>
  )
}

const PasswordInput = ({ iconName, type, ...props }) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false)

  const classes = ['Input']
  const isPassword = type === 'password'
  if (isPassword) classes.push('Input-password')

  const inputType = isPassword && passwordVisibility ? 'text' : type

  return (
    <div className={classes.join(' ')}>
      { iconName && <div className="Input-icon"><Icon width={16} height={16} fill="var(--grey-medium)" name={iconName} /></div> }
      <input type={inputType} {...props} />
      { isPassword && <div className="Input-icon Input-icon-password" onClick={() => setPasswordVisibility(!passwordVisibility)}><Icon width={20} height={20} fill="var(--grey-medium-dark)" name="eye" /></div> }
    </div>
  )
}

Input.propTypes = {
  iconName: PropTypes.string,
  type: PropTypes.string
}

const Label = ({ htmlFor, children }) => {
  return (
    <label htmlFor={htmlFor} className="Input-label">
      <Text color="grey-super-dark" size={14} weight={400}>{children}</Text>
    </label>
  )
}

Label.propTypes = {
  htmlFor: PropTypes.string,
  children: PropTypes.node
}

const FormControl = ({ error, children }) => {
  const classes = ['FormControl']
  if (error) classes.push('FormControl-error')
  return (
    <div className={classes.join(' ')}>
      {children}
      {error &&
        <div className="FormControl-error-message">
          <Icon name="danger" width={3} height={12} fill="var(--error)" />
          <Text color="error" size={12} weight={400}>{error.message}</Text>
        </div>
      }
    </div>
  )
}

FormControl.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  children: PropTypes.node
}

export default Input
export { Label, FormControl }
