import Parse from 'parse'

const conversion = {
  // to: {
  //   from: multiply by
  // },
  yearly: {
    'half-yearly': 2,
    quarterly: 4,
    monthly: 12,
    daily: 365
  },
  'half-yearly': {
    yearly: 0.5,
    quarterly: 2,
    monthly: 6,
    daily: 182.5
  },
  quarterly: {
    yearly: 0.25,
    'half-yearly': 0.5,
    monthly: 3,
    daily: 90
  },
  monthly: {
    yearly: 1 / 12,
    'half-yearly': 1 / 6,
    quarterly: 1 / 3,
    daily: 30
  },
  daily: {
    yearly: 1 / 365,
    'half-yearly': 1 / 182,
    quarterly: 1 / 90,
    monthly: 1 / 30
  }
}

function getCostAs (item, interval) {
  let price, itemInterval
  if (item instanceof Parse.Object) {
    price = item.get('price')
    itemInterval = item.get('priceInterval')
  } else {
    price = item.price
    itemInterval = item.interval
  }

  if (itemInterval === interval) {
    return price
  }

  return price * conversion[interval][itemInterval]
}

export default getCostAs
