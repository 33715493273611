import React, { useState, useEffect } from 'react'
import './Environments.scss'
import Block from '../../components/Block/Block.js'
import Text from '../../components/Text/Text.js'
import Loader from '../../components/Loader/Loader.js'
import Tag from '../../components/Tag/Tag.js'
import getUrlWithAuth from '../../utils/getUrlWithAuth.js'

const Environments = ({ website, isLoading }) => {
  const [stagingWebsite, setStagingWebsite] = useState(null)

  const productionUrl = website && website.get('url')
  const stagingUrl = stagingWebsite && getUrlWithAuth(stagingWebsite)

  useEffect(() => {
    let isUnmounting = false
    if (!website) return
    async function getStagingWebsite () {
      const stagingWebsite = website.get('stagingWebsite')
      if (!stagingWebsite) return
      await stagingWebsite.fetch()
      if (isUnmounting) return
      setStagingWebsite(stagingWebsite)
    }
    getStagingWebsite()
    return () => {
      isUnmounting = true
    }
  }, [website])

  return (
    <Block>
      <div className="Website-environments-title">
        <Text size={16} weight={700} color="secondary">Environments</Text>
        { isLoading && <Loader /> }
      </div>
      <div className="Website-environments-tags">
        { productionUrl && <Tag color="red-light" name="Production" target={productionUrl}></Tag> }
        { stagingUrl && <Tag color="yellow-light" name="Staging" target={stagingUrl}></Tag> }
      </div>
    </Block>
  )
}

export default Environments
