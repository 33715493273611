import React, { useState, useEffect } from 'react'
import './Plugins.scss'
import Text from '../../components/Text/Text.js'
import Block from '../../components/Block/Block.js'
import Table from '../../components/Table/Table.js'
import Icon from '../../components/Icon/Icon.js'
import Select from '../../components/Select/Select.js'
import Loader from '../../components/Loader/Loader.js'
import Pagination from '../../components/Pagination/Pagination.js'
import useWebsites from '../../hooks/useWebsites.js'
import usePageTitle from '../../hooks/usePageTitle.js'
import useErrorMessage from '../../hooks/useErrorMessage.js'
import history from '../../history.js'
import toEuro from '../../utils/toEuro.js'

const Price = ({ price, isActive }) => {
  return <Text size={14} weight={isActive ? 700 : 400}>{toEuro(price || '0')}</Text>
}

const Plugins = () => {
  const [sortStatus, setSortStatus] = useState('name')
  const [loadingWebsites, websites, websitesError] = useWebsites()
  const [plugins, setPlugins] = useState(new Map())
  const [pluginsError, setPluginsError] = useState(null)
  const [bundles, setBundles] = useState(new Map())
  const [showWebsite, setShowWebsite] = useState('all')
  const [itemsPerPage, setItemsPerPage] = useState('10')
  const [page, setPage] = useState(1)
  const [loadingPlugins, setLoadingPlugins] = useState(false)
  const [activeRow, setActiveRow] = useState(null)
  usePageTitle('Plugins')

  useErrorMessage([websitesError, pluginsError])

  useEffect(() => {
    let unmounting = false
    async function getPlugins () {
      setLoadingPlugins(true)
      const promises = []
      const bundlePromises = []
      for (let i = 0, l = websites.length; i < l; i++) {
        const plugins = websites[i].get('activePlugins')
        promises.push(plugins.query().find())
        const bundles = websites[i].get('wordPressPluginBundles')
        const bundleQuery = bundles.query()
        bundlePromises.push(bundleQuery.find())
      }
      let results, bundleResults, error
      try {
        results = await Promise.all(promises)
        bundleResults = await Promise.all(bundlePromises)
      } catch (err) {
        error = err
      }
      if (error) {
        setPluginsError(error)
      } else {
        const allPlugins = new Map()
        const allBundles = new Map()
        for (let i = 0, l = websites.length; i < l; i++) {
          allPlugins.set(
            websites[i],
            results[i]
          )
          allBundles.set(
            websites[i],
            bundleResults[i]
          )
        }
        if (unmounting) return
        setPlugins(allPlugins)
        setBundles(allBundles)
      }
      setLoadingPlugins(false)
    }
    getPlugins()
    return () => {
      unmounting = true
    }
  }, [websites])

  const groupedPlugins = new Map()
  for (const [website, websitePlugins] of plugins.entries()) {
    if (showWebsite !== 'all' && website.id !== showWebsite) continue
    for (const plugin of websitePlugins) {
      const plgn = groupedPlugins.get(plugin.id) || {
        type: 'plugin',
        plugin,
        name: plugin.get('name'),
        sortName: plugin.get('name').toLowerCase(),
        cost: plugin.get('priceToCharge'),
        websites: new Set(),
        liveInstallations: 1
      }
      plgn.websites.add(website)
      plgn.liveInstallations = plgn.websites.size
      groupedPlugins.set(plugin.id, plgn)
    }
  }

  for (const [website, websiteBundles] of bundles.entries()) {
    if (showWebsite !== 'all' && website.id !== showWebsite) continue
    for (const bundle of websiteBundles) {
      const plgn = groupedPlugins.get(bundle.id) || {
        type: 'bundle',
        bundle,
        name: bundle.get('name'),
        sortName: bundle.get('name').toLowerCase(),
        cost: bundle.get('priceToCharge'),
        websites: new Set(),
        liveInstallations: 1
      }
      plgn.websites.add(website)
      plgn.liveInstallations = plgn.websites.size
      groupedPlugins.set(bundle.id, plgn)
    }
  }

  let sortBy = sortStatus[0] === '-' ? sortStatus.slice(1) : sortStatus
  if (sortBy === 'name') sortBy = 'sortName'
  const sortedItems = [...groupedPlugins.values()].sort((a, b) => {
    let num = 0
    if (a[sortBy] < b[sortBy]) num = -1
    if (a[sortBy] > b[sortBy]) num = 1
    return num
  })
  if (sortStatus[0] === '-') {
    sortedItems.reverse()
  }
  const totalItems = sortedItems.length

  const itemsOnPage = sortedItems.slice(
    itemsPerPage * (page - 1),
    itemsPerPage * page
  )

  const headers = [{
    id: 'fill',
    name: '',
    flex: '0 0 31px'
  }, {
    id: 'name',
    name: 'Name',
    sortName: 'name',
    flex: '1 0 376px'
  },
  {
    id: 'liveInstallations',
    name: 'Live installations',
    sortName: 'liveInstallations',
    flex: '1 0 200px'
  },
  {
    id: 'cost',
    name: 'Yearly cost',
    sortName: 'cost',
    flex: '1 0 200px'
  }]

  function handleRowClick (item) {
    return e => {
      const myId = item.plugin.id
      if (activeRow && activeRow.plugin.id === myId) {
        setActiveRow(null)
      } else {
        setActiveRow(item)
      }
    }
  }

  function handleWebsiteRowClick (id) {
    return e => history.push(`/website/${id}`)
  }

  const Row = ({ item }) => {
    const myId = item.plugin.id
    const isActive = activeRow && (activeRow.plugin.id === myId)
    const classes = ['Plugins-row-content']
    if (isActive) classes.push('Table-row-active')
    return (
      <div className="Plugins-row">
        <div className={classes.join(' ')} onClick={handleRowClick(item)}>
          <div className="Plugins-row-caret">
            <Icon name={isActive ? 'arrowUp' : 'arrowDown'} width={7} height={7} />
          </div>
          <div className="Plugins-row-name">
            <Text size={14} weight={700}>{item.name}</Text>
          </div>
          <div className="Plugins-row-installations">
            <Text size={14} weight={isActive ? 700 : 400}>{item.liveInstallations}</Text>
          </div>
          <div className="Plugins-row-cost">
            <Price price={item.cost} isActive={isActive} />
          </div>
        </div>
        <div className="Plugins-row-collapsed-content">
          { isActive && <CollapsedContent item={item} onClick={handleWebsiteRowClick} />}
        </div>
      </div>
    )
  }

  function handleViewChange (value) {
    setPage(1)
    setShowWebsite(value)
  }

  function handleItemsPerPageChange (value) {
    setPage(1)
    setItemsPerPage(value)
  }

  const websiteOptions = websites.map(website => ({ value: website.id, label: website.get('url') }))
  websiteOptions.unshift({ value: 'all', label: 'all websites' })

  const availableItemsPerPageOptions = [{
    value: '10',
    label: '10'
  }, totalItems > 25 && {
    value: '25',
    label: '25'
  }, totalItems > 50 && {
    value: '50',
    label: '50'
  }].reduce((accumulator, currentValue) => currentValue ? [...accumulator, currentValue] : accumulator, [])

  const isLoading = loadingWebsites || loadingPlugins

  return (
    <div className="Plugins">
      <div className="Plugins-title">
        <Text color="secondary" size={20} weight={700}>Plugins</Text>
        { isLoading && <div className="Plugins-title-loader"><Loader /></div> }
        <div className="Plugins-title-fill"></div>
        <Select
          value={showWebsite}
          onChange={handleViewChange}
          label="View:"
          options={websiteOptions}
        />
      </div>
      <Block>
        <Table
          getKey={item => (item.plugin || item.bundle).id}
          items={itemsOnPage}
          headers={headers}
          Row={Row}
          sortStatus={sortStatus}
          setSortStatus={setSortStatus}
        />
        { totalItems > itemsPerPage &&
          <div className="Plugins-footer">
            <Text size={12} weight={400} color="grey-dark">{`${page * itemsPerPage > totalItems ? totalItems % itemsPerPage : itemsPerPage} of ${totalItems} invoices`}</Text>
            { totalItems > 10 && <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              label="Items per page:"
              options={availableItemsPerPageOptions}
            /> }
            <div className="Plugins-footer-pagination">
              <Pagination
                total={totalItems}
                itemsPerPage={~~itemsPerPage}
                page={page}
                onChange={page => setPage(page)}
              />
            </div>
          </div>
        }
      </Block>
    </div>
  )
}

const CollapsedContent = ({ item, onClick }) => {
  const websites = item.websites
  return (
    <div>
      {[...websites].map(website => <CollapsedContentView key={website.id} item={item} website={website} onClick={onClick(website.id)} />)}
    </div>
  )
}

const CollapsedContentView = ({ item, website, onClick }) => {
  const [pluginIsBundled, setPluginIsBundled] = useState('')

  useEffect(() => {
    let unmounting = false
    if (item.type !== 'plugin') return
    async function getBundles () {
      let isBundled = false
      const pluginSlug = item.plugin.get('slug')
      const bundleQuery = website.get('wordPressPluginBundles').query()
      const bundles = await bundleQuery.find()
      for (const bundle of bundles) {
        if (isBundled) continue
        isBundled = (bundle.get('wordPressPluginSlugs') || []).some(slug => slug === pluginSlug)
      }
      if (unmounting) return
      setPluginIsBundled(isBundled)
    }
    getBundles()
    return () => {
      unmounting = true
    }
  }, [item, website])

  return (
    <div className="Plugins-collapsed-content">
      <div className="Plugins-collapsed-content-website" onClick={onClick}><Text size={14} color="grey-super-dark" weight={400}>{website.get('url')}</Text></div>
      <div className="Plugins-collapsed-content-price"><Price price={pluginIsBundled ? '0' : item.cost} /></div>
    </div>
  )
}

export default Plugins
