import React, { useReducer } from 'react'
import './Login.scss'
import Parse from 'parse'
import history from '../../history.js'
import { setUser } from '../../redux/user/actions.js'
import { useDispatch } from 'react-redux'
import Input, { Label, FormControl } from '../../components/Input/Input.js'
import Text from '../../components/Text/Text.js'
import Button from '../../components/Button/Button.js'
import usePageTitle from '../../hooks/usePageTitle.js'
import useActiveClient from '../../hooks/useActiveClient.js'

const initialState = {
  email: '',
  password: '',
  invalidCredentials: false,
  missingValues: new Set()
}

function reducer (state, action) {
  switch (action.type) {
    case 'setFormValue': {
      return {
        ...state,
        [action.name]: action.value,
        invalidCredentials: false
      }
    }
    case 'setInvalidCredentials': {
      return {
        ...state,
        invalidCredentials: action.value
      }
    }
    case 'setMissingValues': {
      const newValues = new Set(state.missingValues)
      if (action.remove) {
        (action.values || []).forEach(name => newValues.delete(name))
      } else {
        (action.values || []).forEach(name => newValues.add(name))
      }
      return {
        ...state,
        missingValues: newValues
      }
    }
    default: return state
  }
}

async function initialiseClient (user, setActiveClient, dispatch) {
  const activeClient = window.localStorage.getItem('activeClient')
  if (!activeClient) {
    if (new Set(user.get('roles') || []).has('admin')) {
      dispatch({ type: 'SHOW_CLIENTS' })
    } else {
      const clients = await user.get('clients').query().find()
      if (clients.length > 1) {
        dispatch({ type: 'SHOW_CLIENTS' })
      } else {
        const client = clients[0]
        setActiveClient({
          id: client.id,
          companyId: (client.get('teamleaderCompany') || {}).id,
          reseller: (client.get('reseller') || false),
          resellerDiscount: client.get('resellerDiscount')
        })
      }
    }
  }
}

const Login = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const reduxDispatch = useDispatch()
  const [, setActiveClient] = useActiveClient()
  usePageTitle('Login')

  function handleChange (name) {
    return e => {
      dispatch({
        type: 'setMissingValues',
        values: [name],
        remove: true
      })
      dispatch({
        type: 'setFormValue',
        name,
        value: e.target.value
      })
    }
  }

  async function handleSubmit (e) {
    e.preventDefault()
    const missingValues = []
    if (!state.email) missingValues.push('email')
    if (!state.password) missingValues.push('password')
    if (missingValues.length) {
      return dispatch({
        type: 'setMissingValues',
        values: missingValues,
        remove: false
      })
    }

    if (state.email && state.password) {
      try {
        const user = await Parse.User.logIn(
          state.email,
          state.password
        )
        // TODO cancel login request when unmounting
        reduxDispatch(setUser(user))
        await initialiseClient(user, setActiveClient, reduxDispatch)
        history.push('/')
      } catch (error) {
        if (error.code === 101) {
          dispatch({
            type: 'setInvalidCredentials',
            value: true
          })
        } else {
          console.error(error)
          // TODO show error
        }
      }
    } else {
      dispatch({
        type: 'setInvalidCredentials',
        value: true
      })
    }
  }

  function getError (name) {
    if (state.missingValues.has(name)) {
      return { message: 'Field is required' }
    }
    if (state.invalidCredentials) {
      return { message: 'Invalid credentials' }
    }
    return null
  }

  return (
    <div className="Login-root">
      <div className="Login-title"><Text size={20} weight={700} color="secondary">Log in</Text></div>
      <form onSubmit={handleSubmit}>
        <FormControl error={getError('email')}>
          <Label htmlFor="input-email">Email address</Label>
          <Input id="input-email" type="email" value={state.email} onChange={handleChange('email')} iconName="person" />
        </FormControl>
        <FormControl error={getError('password')}>
          <Label htmlFor="input-password">Password</Label>
          <Input id="input-password" type="password" value={state.password} onChange={handleChange('password')} iconName="key" />
        </FormControl>
        <div>
          <Button buttonStyle="primary" type="submit">Log in</Button>
          {/* <Button buttonStyle="secondary" type="button">Register</Button> */}
        </div>
      </form>
      {/* <div className="Login-footer"><Button buttonStyle="secondary-text">Forgot your password?</Button></div> */}
    </div>
  )
}

export default Login
