const formatter = new Intl.NumberFormat('nl-BE', { style: 'currency', currency: 'EUR' })

function toEuro (number) {
  if (Object.prototype.toString.apply(number).slice(8, -1) !== 'Number') number = 0
  return formatter.format(number)
}

export default toEuro
export {
  formatter
}
