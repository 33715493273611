const defaultState = {
  user: null
}

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_USER': {
      return { user: action.user }
    }

    case 'SHOW_CLIENTS': {
      return {
        ...state,
        showclients: true
      }
    }

    case 'HIDE_CLIENTS': {
      return {
        ...state,
        showclients: false
      }
    }

    default: return state
  }
}

export default userReducer
