import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import './Profile.scss'
import Text from '../../components/Text/Text.js'
import Block from '../../components/Block/Block.js'
import Button from '../../components/Button/Button.js'
import Input, { Label, FormControl } from '../../components/Input/Input.js'
import { createSnackbarItem, createSnackbarError } from '../../components/Snackbar/Snackbar.js'
import Loader from '../../components/Loader/Loader.js'
import usePageTitle from '../../hooks/usePageTitle.js'
import useUser from '../../hooks/useUser.js'
import useActiveClient from '../../hooks/useActiveClient.js'

const Profile = () => {
  const [user] = useUser()
  const [activeClientObject] = useActiveClient()
  const [clients, setClients] = useState([])
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [currentPasswordError, setCurrentPasswordError] = useState(null)
  const [saveError, setSaveError] = useState(null)
  const [saving, setSaving] = useState(false)

  const dispatch = useDispatch()
  usePageTitle('Profile')

  const client = clients.find(({ id }) => id === activeClientObject.id)
  const clientName = client ? client.get('clientName') : ''

  useEffect(() => {
    let unmounting = false
    if (!user) return
    async function getClients () {
      const clientsQuery = user.get('clients').query()
      const clients = await clientsQuery.find()
      if (!unmounting) {
        setClients(clients)
      }
    }
    getClients()
    return () => {
      unmounting = true
    }
  }, [user])

  function handleChangeClick () {
    dispatch({ type: 'SHOW_CLIENTS' })
  }

  async function handleSubmit (e) {
    e.preventDefault()
    if (saving) return
    if (!oldPassword || !newPassword) {
      setSaveError({ message: 'Field is required' })
      return setCurrentPasswordError({ message: 'Field is required' })
    }
    setCurrentPasswordError(null)
    setSaveError(null)
    try {
      await user.verifyPassword(oldPassword)
    } catch (error) {
      createSnackbarError({ error })
      return setCurrentPasswordError(error)
    }
    user.setPassword(newPassword)
    try {
      setSaving(true)
      await user.save()
      createSnackbarItem({ message: 'New password saved' })
      setOldPassword('')
      setNewPassword('')
    } catch (error) {
      setSaveError(error)
      createSnackbarError({ error })
    } finally {
      setSaving(false)
    }
  }

  // if user is admin or clients.length > 1
  let showClientsButton = false
  if (user) {
    const roles = new Set(user.get('roles') || [])
    if (roles.has('admin')) {
      showClientsButton = true
    }
  }
  if (clients.length > 1) {
    showClientsButton = true
  }

  return (
    <div className="Profile">
      <div className="Profile-title">
        <Text color="secondary" size={20} weight={700}>Profile</Text>
      </div>
      <div className="Profile-blocks">
        <div>
          <Block>
            <div className="Profile-block-title">
              <Text size={16} weight={700} color="secondary">User</Text>
            </div>
            <div className="Profile-block-tags">
              <Text size={16} weight={400} color="grey-super-dark">{user.get('username')}</Text>
            </div>
          </Block>
          <Block>
            <div className="Profile-block-title">
              <Text size={16} weight={700} color="secondary">Change password</Text>
            </div>
            <div className="Profile-block-tags">
              <form onSubmit={handleSubmit}>
                <FormControl error={currentPasswordError}>
                  <Label htmlFor="input-password">Old assword</Label>
                  <Input id="input-old-password" type="password" value={oldPassword} onChange={(e) => { setOldPassword(e.target.value) }} iconName="key" />
                </FormControl>
                <FormControl error={saveError}>
                  <Label htmlFor="input-password">New password</Label>
                  <Input id="input-new-password" type="password" value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} iconName="key" />
                </FormControl>
                <div className="Profile-password-submit">
                  <br/>
                  <Button disabled={saving} buttonStyle="primary" type="submit">{saving ? <Loader /> : 'Submit'}</Button>
                </div>
              </form>
            </div>
          </Block>
        </div>
        <div>
          { showClientsButton &&
          <Block>
            <div className="Profile-block-title">
              <Text size={16} weight={700} color="secondary">Client switch</Text>
            </div>
            <div className="Profile-block-clients">
              <Text>{`Client name: ${clientName}`}</Text>
              { showClientsButton && <div className="Profile-button"><br/><Button onClick={handleChangeClick}>Change clients</Button></div> }
            </div>
          </Block>}
        </div>
      </div>
    </div>
  )
}

export default Profile
