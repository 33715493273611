import React, { useState, useEffect, useMemo } from 'react'
import './Websites.scss'
import history from '../../history.js'
import Block from '../../components/Block/Block.js'
import Text from '../../components/Text/Text.js'
import Table from '../../components/Table/Table.js'
import Tag from '../../components/Tag/Tag.js'
import Loader from '../../components/Loader/Loader.js'
import Icon from '../../components/Icon/Icon.js'
import ResellerIcon from '../../components/ResellerIcon/ResellerIcon.js'
import getUrlWithAuth from '../../utils/getUrlWithAuth.js'
import getCostAs from '../../utils/getCostAs.js'
import intervalOptions from '../../utils/intervalOptions.js'
import toEuro from '../../utils/toEuro.js'
import applyDiscount from '../../utils/applyDiscount.js'
import useWebsites from '../../hooks/useWebsites.js'
import useErrorMessage from '../../hooks/useErrorMessage.js'
import useCosts from '../../hooks/useCosts.js'
import usePaymentInterval from '../../hooks/usePaymentInterval.js'
import useActiveClient from '../../hooks/useActiveClient.js'

const Websites = () => {
  const [activeClientObject] = useActiveClient()
  const [loadingWebsites, websites, websitesError] = useWebsites({ clientId: activeClientObject.id })
  const [loadingCosts, costs, costsError] = useCosts({ websites, clientId: activeClientObject.id })
  const [mappedWebsites, setMappedWebsites] = useState([])
  const [loadingPlugins, setLoadingPlugins] = useState(false)
  const [activeRow, setActiveRow] = useState(null)
  const [paymentInterval] = usePaymentInterval()

  useErrorMessage([websitesError, costsError])

  const sumTotalCosts = useMemo(() => {
    return mappedWebsites.reduce((accumulator, currentValue) => {
      try {
        if (activeClientObject.reseller) {
          return accumulator + currentValue.cost.resellerTotalNum
        } else {
          return accumulator + currentValue.cost.totalNum
        }
      } catch (error) {
        return accumulator
      }
    }, 0)
  }, [mappedWebsites, activeClientObject.reseller])
  const converterSumTotalCosts = getCostAs({
    price: sumTotalCosts,
    interval: 'yearly'
  }, paymentInterval)

  useEffect(() => {
    if (loadingWebsites || loadingCosts) return
    let unmounting = false
    async function getPlugins () {
      setLoadingPlugins(true)
      const pluginPromises = []
      const bundlePromises = []

      for (let i = 0, l = websites.length; i < l; i++) {
        const website = websites[i]
        const plugins = website.get('activePlugins')
        pluginPromises.push(plugins.query().find())
        const bundles = website.get('wordPressPluginBundles')
        bundlePromises.push(bundles.query().find())
      }

      const plugins = await Promise.all(pluginPromises)
      const bundles = await Promise.all(bundlePromises)
      if (unmounting) return

      const websitesWithPluginsAndBundlesAndCosts = websites.map((website, i) => {
        const myId = website.id
        const item = {
          website,
          plugins: plugins[i],
          bundles: bundles[i],
          costs: costs.filter(cost => (cost.get('website') || {}).id === myId)
        }
        item.cost = getCost(item, activeClientObject)
        return item
      })

      setMappedWebsites(websitesWithPluginsAndBundlesAndCosts)
      setLoadingPlugins(false)
    }
    getPlugins()
    return () => {
      unmounting = true
    }
  }, [websites, costs, loadingWebsites, loadingCosts, activeClientObject])

  const headers = [{
    id: 'fill',
    name: '',
    flex: '0 0 31px'
  }, {
    id: 'url',
    name: 'URL',
    flex: '1 0 376px'
  }, {
    id: 'cost',
    name: `${(intervalOptions.find(({ value }) => paymentInterval === value) || {}).label || 'Yearly'} cost`,
    flex: '1 0 200px'
  }, {
    id: 'environments',
    name: 'Environments',
    flex: '1 0 200px'
  }]

  function handleRowClick (item) {
    return e => {
      const myId = item.website.id
      if (activeRow && activeRow.website.id === myId) {
        setActiveRow(null)
      } else {
        setActiveRow(item)
      }
    }
  }

  const Row = ({ item }) => {
    const website = item.website
    const myId = website.id
    const isActive = activeRow && (activeRow.website.id === myId)
    const classes = ['RecurringCosts-websites-row-content']
    if (isActive) classes.push('Table-row-active')
    const convertedCost = getCostAs({
      price: activeClientObject.reseller ? item.cost.resellerTotalNum : item.cost.totalNum,
      interval: 'yearly'
    }, paymentInterval)
    return (
      <div className="RecurringCosts-websites-row">
        <div className={classes.join(' ')} onClick={handleRowClick(item)}>
          <div className="RecurringCosts-websites-row-caret">
            <Icon name={isActive ? 'arrowUp' : 'arrowDown'} width={7} height={7} />
          </div>
          <div className="RecurringCosts-websites-row-url"><Text size={14} weight={700}>{item.website.get('url')}</Text></div>
          <div className="RecurringCosts-websites-row-cost"><Text size={14} weight={700}>{toEuro(convertedCost)}</Text></div>
          <div className="RecurringCosts-websites-row-environments">
            <Tag color="red-light" name="Production" target={website.get('url')}></Tag>
            { website.get('stagingWebsite') && <Tag color="yellow-light" name="Staging" target={getUrlWithAuth(website.get('stagingWebsite'))}></Tag> }
          </div>
        </div>
        <div>
          { isActive && <CollapsedRow item={item} activeClientObject={activeClientObject} /> }
        </div>
      </div>
    )
  }

  const isLoading = loadingWebsites || loadingPlugins || loadingCosts

  return (
    <Block>
      <div className="RecurringCosts-block-title">
        <Text size={16} weight={700} color="secondary">Websites</Text>
        { isLoading && <Loader /> }
      </div>
      <Table
        items={mappedWebsites}
        headers={headers}
        Row={Row}
        getKey={item => item.website.id}
      />
      <div className="RecurringCosts-webites-total">
        <div className="RecurringCosts-webites-total-title"><Text size={14} color="grey-super-dark" weight={700}>Total</Text></div>
        <div className="RecurringCosts-webites-total-price"><Text size={14} color="grey-super-dark" weight={700}>{toEuro(converterSumTotalCosts || 0)}</Text></div>
        <div className="RecurringCosts-webites-total-fill"></div>
      </div>
    </Block>
  )
}

const CollapsedRow = ({ item, activeClientObject }) => {
  const [paymentInterval] = usePaymentInterval()

  function handleAnchorClick (e) {
    e.preventDefault()
    history.push(`/website/${item.website.id}`)
  }

  function renderWithDiscount (fullPrice, discountPrice) {
    return <><del>{toEuro(fullPrice)} </del> => {toEuro(discountPrice)}</>
  }

  const pluginPrice = (() => {
    const convertedPluginsCost = getCostAs({
      price: item.cost.pluginsNum,
      interval: 'yearly'
    }, paymentInterval)
    if (activeClientObject.reseller) {
      const convertedResellerCost = getCostAs({
        price: item.cost.resellerPluginsNum,
        interval: 'yearly'
      }, paymentInterval)
      return renderWithDiscount(convertedPluginsCost, convertedResellerCost)
    } else {
      return toEuro(convertedPluginsCost)
    }
  })()

  const otherCosts = item.costs
  const hasCostWithTypePlugin = otherCosts.some(cost => cost.get('type') === 'Plugins')

  return (
    <>
      { !hasCostWithTypePlugin &&
        <div className="RecurringCosts-websites-collapsed-row">
          <div className="RecurringCosts-websites-collapsed-row-name">
            <div>
              <a href={`/website/${item.website.id}`} onClick={handleAnchorClick}><Text size={14} weight={400} color="grey-super-dark">Plugins</Text></a>
              {!!activeClientObject.resellerDiscount && <>
                  <ResellerIcon />
                  <Text size={14} color="grey-super-dark">{`korting ${(activeClientObject.resellerDiscount || 0) * 100}%`}</Text>
                </>
              }
            </div>
          </div>
          <div className="RecurringCosts-websites-collapsed-row-cost">
            <Text size={14} weight={400} color="grey-super-dark">{pluginPrice}</Text>
          </div>
          <div className="RecurringCosts-websites-collapsed-row-fill"></div>
        </div>
      }
      { otherCosts.map(cost => {
        const discount = cost.get('discount')
        const fullPrice = getCostAs(cost, paymentInterval)
        return (
          <div key={cost.id} className="RecurringCosts-websites-collapsed-row">
            <div className="RecurringCosts-websites-collapsed-row-name">
              <div>
                <Text size={14} weight={400} color="grey-super-dark">{cost.get('name')}</Text>
                {!!discount && <>
                    <ResellerIcon />
                    <Text size={14} color="grey-super-dark">{`korting ${(discount || 0) * 100}%`}</Text>
                  </>
                }
              </div>
            </div>
            <div className="RecurringCosts-websites-collapsed-row-cost">
              <Text size={14} weight={400} color="grey-super-dark">{!discount ? toEuro(fullPrice) : renderWithDiscount(fullPrice, applyDiscount(fullPrice, discount))}</Text>
            </div>
            <div className="RecurringCosts-websites-collapsed-row-fill"></div>
          </div>
        )
      })}
    </>
  )
}

function getCost (item, activeClientObject) {
  const pluginsPriceBreakdown = item.website.get('pluginsPriceBreakdown')

  const totalPluginPrice = pluginsPriceBreakdown.pluginsTotal + pluginsPriceBreakdown.bundlesTotal
  const resellerPluginPrice = totalPluginPrice - (totalPluginPrice * (activeClientObject.resellerDiscount || 0))

  const totalHostPrice = 0

  const totalOtherCosts = item.costs.reduce((accumulator, currentValue) => accumulator + (currentValue.get('type') === 'Plugins' ? 0 : getCostAs(currentValue, 'yearly')), 0)

  const total = totalPluginPrice + totalHostPrice + totalOtherCosts
  const resellerTotal = resellerPluginPrice + totalHostPrice + totalOtherCosts
  return {
    totalNum: total,
    total: toEuro(total),
    resellerTotalNum: resellerTotal,
    resellerTotal: toEuro(resellerTotal),
    pluginsNum: totalPluginPrice,
    plugins: toEuro(totalPluginPrice),
    resellerPluginsNum: resellerPluginPrice,
    resellerPlugins: toEuro(resellerPluginPrice),
    pluginCount: pluginsPriceBreakdown.plugins.length
  }
}

export default Websites
