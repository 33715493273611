import Popup from '../Popup/Popup.js'
import Text from '../Text/Text.js'
import Icon from '../Icon/Icon.js'
import useActiveClient from '../../hooks/useActiveClient.js'

const ResellerIcon = () => {
  const [activeClient] = useActiveClient()
  const discount = (activeClient.resellerDiscount || 0) * 100
  return (
    <Popup content=<Text size={12} color="white">{`You have a reseller discount of -${discount}%!`}</Text>><Icon fill="var(--grey-medium-dark)" name="discount" /></Popup>
  )
}

export default ResellerIcon
