import React from 'react'
import './Loader.scss'

const Loader = () => {
  return (
    <div className="Loader">
      <svg width="1em" height="1em" viewBox="0 0 80 80">
        <circle cx="40" cy="40" r="30" fill="transparent" stroke="var(--grey-super-dark)" strokeWidth="10" strokeDasharray="10, 20" strokeLinecap="round" />
      </svg>
    </div>
  )
}

export default Loader
