import { useState, useEffect } from 'react'
import Parse from 'parse'
const Cost = Parse.Object.extend('Cost')
const Website = Parse.Object.extend('Website')
const Client = Parse.Object.extend('Client')

async function _getCosts ({ websiteId, websites, clientId } = {}) {
  let costs = []
  if (!clientId && !websiteId) return costs

  const costsQuery = new Parse.Query(Cost)
  if (clientId) {
    costsQuery.equalTo('client', new Client({ objectId: clientId }))
  }
  if (websiteId) {
    const website = new Website()
    website.id = websiteId
    costsQuery.equalTo('website', website)
  } else if (websites) {
    costsQuery.containedIn('website', websites)
  }
  costsQuery.ascending('name')
  costs = await costsQuery.find()

  return costs
}

function useCosts ({ websiteId, websites, clientId } = {}) {
  const [costs, setCosts] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    let unmounting = false
    async function getCosts () {
      setLoading(true)
      let costs, error
      try {
        costs = await _getCosts({ websiteId, websites, clientId })
      } catch (err) {
        error = err
      }
      if (unmounting) return
      if (!error) {
        setCosts(costs)
      } else {
        setError(error)
      }
      setLoading(false)
    }
    getCosts()
    return () => {
      unmounting = true
    }
  }, [websiteId, websites, clientId])

  return [loading, costs, error]
}

export default useCosts
