import { useState, useEffect } from 'react'
import Parse from 'parse'
const Website = Parse.Object.extend('Website')
const Client = Parse.Object.extend('Client')

async function _getWebsites ({ clientId } = {}) {
  let websites = []
  if (!clientId) return websites

  const websitesQuery = new Parse.Query(Website)
  websitesQuery.equalTo('production', true)
  websitesQuery.equalTo('deleted', false)
  websitesQuery.equalTo('client', new Client({ objectId: clientId }))
  websitesQuery.include('stagingWebsite')
  websites = await websitesQuery.find()

  return websites
}

function useWebsites ({ clientId } = {}) {
  const [websites, setWebsites] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    let unmounting = false
    async function getWebsites () {
      setLoading(true)
      let websites, error
      try {
        websites = await _getWebsites({ clientId })
      } catch (err) {
        error = err
      }
      if (unmounting) return
      if (!error) {
        setWebsites(websites)
      } else {
        setError(error)
      }
      setLoading(false)
    }
    getWebsites()
    return () => {
      unmounting = true
    }
  }, [clientId])

  return [loading, websites, error]
}

export default useWebsites
