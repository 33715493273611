import { useState } from 'react'
import Parse from 'parse'

function useUser () {
  const [user, setUser] = useState(Parse.User.current())

  async function updateUser (update) {
    const updatedUser = await user.save(update)
    setUser(updatedUser)
  }

  return [user, updateUser]
}

export default useUser
