import React from 'react'
import './Tag.scss'
import Text from '../Text/Text.js'
import Icon from '../Icon/Icon.js'
import PropTypes from 'prop-types'
import asUrl from '../../utils/asUrl.js'

const Wrapper = ({ target, children }) => {
  return target
    ? <a className="Tag-url" href={asUrl(target)} target="_blank" rel="noreferrer noopener" onClick={e => e.stopPropagation()}>{children}</a>
    : <div>{children}</div>
}

const Tag = ({ name, target, color, size = 'normal', circle, children }) => {
  const classes = [
    'Tag',
    `color-${color}`,
    `size-${size}`
  ]
  if (target) classes.push('url')
  if (circle) classes.push('Tag-circle')
  return (
    <Wrapper target={target}>
      <div className={classes.join(' ')}>
        { children || <Text size={10} weight={500}>{name}</Text> }
        { target && <div className="Tag-icon"><Icon width={9} height={9} name="external" /></div> }
      </div>
    </Wrapper>
  )
}

Tag.propTypes = {
  name: PropTypes.string,
  target: PropTypes.string,
  color: PropTypes.oneOf(['red-light', 'yellow-light', 'orange', 'red', 'green', 'blue']),
  size: PropTypes.oneOf(['extra-small', 'small', 'normal']),
  circle: PropTypes.bool,
  children: PropTypes.node
}

export default Tag
