import React from 'react'
import Text from '../../components/Text/Text.js'
import usePageTitle from '../../hooks/usePageTitle.js'
import './ErrorPage.scss'
import Icon from '../../components/Icon/Icon.js'
import history from '../../history.js'

const ErrorPage = () => {
  usePageTitle('Whoops, something went wrong')

  function handleBackClick () {
    history.goBack()
  }

  return (
    <div className="ErrorPage">
      <Icon width={94} height={94} name="embarrassed" fill="var(--grey-medium-dark)" />
      <Text size={20} weight={700} color="grey-dark">Whoops, something went wrong</Text>
      <div className="ErrorPage-help">
        <Text size={14} weight={700} color="grey-medium-dark">Go back to the previous page or&nbsp;</Text>
        <div className="ErrorPage-back" onClick={handleBackClick}><Text size={14} weight={700} color="secondary">click here</Text></div>
      </div>
    </div>
  )
}

export default ErrorPage
