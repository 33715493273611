import React from 'react'
import Block from '../../components/Block/Block.js'
import Text from '../../components/Text/Text.js'
import Table from '../../components/Table/Table.js'
import Loader from '../../components/Loader/Loader.js'
import usePostmarkServers from '../../hooks/usePostmarkServers.js'
import useShortPixels from '../../hooks/useShortPixels.js'
import './Credits.scss'
import useErrorMessage from '../../hooks/useErrorMessage.js'
import toEuro from '../../utils/toEuro.js'
import useActiveClient from '../../hooks/useActiveClient.js'

const Credits = () => {
  const [activeClientObject] = useActiveClient()
  const [loadingServers, postmarkServers, serversError] = usePostmarkServers({ clientId: activeClientObject.id })
  const [loadingShortPixels, shortPixels, shortPixelsError] = useShortPixels({ clientId: activeClientObject.id })

  useErrorMessage([serversError, shortPixelsError])

  const headers = [{
    id: 'service',
    name: 'Service',
    flex: '1 0 200px'
  }, {
    id: 'cost',
    name: 'Cost/credit',
    flex: '1 0 100px'
  }, {
    id: 'status',
    name: 'Status',
    flex: '1 0 100px'
  }]

  const combinedItems = [
    ...postmarkServers.map(server => ({ item: server, type: 'postmark' })),
    ...shortPixels.map(short => ({ item: short, type: 'shortPixel' }))
  ]

  const isLoading = loadingServers || loadingShortPixels

  return (
    <Block>
      <div className="RecurringCosts-block-title">
        <Text size={16} weight={700} color="secondary">Credits</Text>
        { isLoading && <Loader /> }
      </div>
      <Table
        getKey={item => item.item ? item.item.id : ''}
        items={combinedItems}
        headers={headers}
        Row={Row}
      />
    </Block>
  )
}

const Row = ({ item }) => {
  const parseItem = item.item

  function getStatus (item) {
    const used = item.get('creditUsed')
    const total = item.get('creditTotal')
    return `${used === 0 ? 0 : (used || '?')} / ${total === 0 ? 0 : (total || '?')}`
  }

  const name = `${item.type === 'postmark' ? 'Postmark' : 'Photocredit'} - ${parseItem.get('name')}`
  const cost = toEuro(parseItem.get('costPerCredit'))
  return (
    <div className="RecurringCosts-credits-row">
      <div className="RecurringCosts-credits-row-service"><Text size={14} weight={700}>{name}</Text></div>
      <div className="RecurringCosts-credits-row-cost"><Text size={14} weight={400}>{cost}</Text></div>
      <div className="RecurringCosts-credits-row-status"><Text size={14} weight={400}>{getStatus(parseItem)}</Text></div>
    </div>
  )
}

export default Credits
