import React, { useMemo } from 'react'
import './Costs.scss'
import Block from '../../components/Block/Block.js'
import Text from '../../components/Text/Text.js'
import Table from '../../components/Table/Table.js'
import Loader from '../../components/Loader/Loader.js'
import ResellerIcon from '../../components/ResellerIcon/ResellerIcon.js'
import usePaymentInterval from '../../hooks/usePaymentInterval.js'
import getCostAs from '../../utils/getCostAs.js'
import intervalOptions from '../../utils/intervalOptions.js'
import toEuro from '../../utils/toEuro.js'
import applyDiscount from '../../utils/applyDiscount.js'

const Costs = ({ costs, isLoading }) => {
  const [paymentInterval] = usePaymentInterval()

  const mappedCosts = useMemo(() => {
    return costs.map(cost => {
      const discount = cost.get('discount')
      return {
        cost,
        price: getCostAs(cost, paymentInterval),
        ...discount && { discountPrice: applyDiscount(getCostAs(cost, paymentInterval), discount), discount }
      }
    })
  }, [costs, paymentInterval])
  const costsSum = mappedCosts.reduce((accumulator, currentValue) => accumulator + (currentValue.price || 0), 0)

  const headers = [{
    id: 'service',
    name: 'Service',
    flex: '1'
  }, {
    id: 'cost',
    name: `${(intervalOptions.find(({ value }) => paymentInterval === value) || {}).label || 'Yearly'} cost`,
    flex: '1',
    alignRight: true
  }]

  return (
    <Block>
      <div className="Website-costs-title">
        <Text size={16} weight={700} color="secondary">Costs</Text>
        { isLoading && <Loader /> }
      </div>
      <Table
        getKey={item => item.cost ? item.cost.id : ''}
        items={mappedCosts}
        headers={headers}
        Row={Row}
      />
      <div className="Website-costs-costs-total">
        <div className="Website-costs-costs-total-title"><Text size={14} color="grey-super-dark" weight={700}>Total</Text></div>
        <div><Text size={14} color="grey-super-dark" weight={700}>{toEuro(costsSum || 0)}</Text></div>
      </div>
    </Block>
  )
}

const Row = ({ item: { cost, price, discountPrice, discount } } = {}) => {
  return (
    <div className="Website-costs-costs-row">
      <div className="Website-costs-costs-row-service"><Text size={14} weight={700}>{cost ? cost.get('name') : ''}</Text>{discountPrice && <><ResellerIcon /><Text size={14}>{`korting ${(discount || 0) * 100}%`}</Text></>}</div>
      <div className="Website-costs-costs-row-cost"><Text size={14} weight={400}>{!discountPrice ? toEuro(price || 0) : getDiscountedDisplay(price, discountPrice)}</Text></div>
    </div>
  )
}

function getDiscountedDisplay (fullPrice, discountPrice) {
  return (<><del>{toEuro(fullPrice || 0)}</del>{` => ${toEuro(discountPrice || 0)}`}</>)
}

export default Costs
