import React, { useMemo } from 'react'
import './GeneralCosts.scss'
import Block from '../../components/Block/Block.js'
import Text from '../../components/Text/Text.js'
import Table from '../../components/Table/Table.js'
import Loader from '../../components/Loader/Loader.js'
import useCosts from '../../hooks/useCosts.js'
import useErrorMessage from '../../hooks/useErrorMessage.js'
import usePaymentInterval from '../../hooks/usePaymentInterval.js'
import useActiveClient from '../../hooks/useActiveClient.js'
import getCostAs from '../../utils/getCostAs.js'
import intervalOptions from '../../utils/intervalOptions.js'
import toEuro from '../../utils/toEuro.js'

const GeneralCosts = () => {
  const [activeClientObject] = useActiveClient()
  const [loadingCosts, costs, costsError] = useCosts({ clientId: activeClientObject.id })
  const [paymentInterval] = usePaymentInterval()

  useErrorMessage([costsError])

  const mappedCosts = useMemo(() => {
    return costs
      .filter(cost => {
        return !cost.get('website')
      })
      .map(cost => {
        return { cost, price: getCostAs(cost, paymentInterval) }
      })
  }, [costs, paymentInterval])
  const costsSum = mappedCosts.reduce((accumulator, currentValue) => accumulator + (currentValue.price || 0), 0)

  const headers = [{
    id: 'service',
    name: 'Service',
    flex: '1'
  }, {
    id: 'cost',
    name: `${(intervalOptions.find(({ value }) => paymentInterval === value) || {}).label || 'Yearly'} cost`,
    flex: '1',
    alignRight: true
  }]

  const isLoading = loadingCosts

  return (
    <Block>
      <div className="RecurringCosts-block-title">
        <Text size={16} weight={700} color="secondary">General costs</Text>
        { isLoading && <Loader /> }
      </div>
      <Table
        getKey={item => item.cost ? item.cost.id : ''}
        items={mappedCosts}
        headers={headers}
        Row={Row}
      />
      <div className="RecurringCosts-costs-total">
        <div className="RecurringCosts-costs-total-title"><Text size={14} color="grey-super-dark" weight={700}>Total</Text></div>
        <div><Text size={14} color="grey-super-dark" weight={700}>{toEuro(costsSum || 0)}</Text></div>
      </div>
    </Block>
  )
}

const Row = ({ item: { cost, price } } = {}) => {
  return (
    <div className="RecurringCosts-costs-row">
      <div className="RecurringCosts-costs-row-service"><Text size={14} weight={700}>{cost ? cost.get('name') : ''}</Text></div>
      <div className="RecurringCosts-costs-row-cost"><Text size={14} weight={400}>{toEuro(price || 0)}</Text></div>
    </div>
  )
}

export default GeneralCosts
