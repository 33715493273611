import React from 'react'
import Text from '../../components/Text/Text.js'
import usePageTitle from '../../hooks/usePageTitle.js'
import './NotFound.scss'
import Icon from '../../components/Icon/Icon.js'

const NotFound = () => {
  usePageTitle('Not found')

  return (
    <div className="NotFound">
      <Icon width={124} height={124} name="space" fill="var(--grey-medium-dark)" />
      <Text size={14} weight={700} color="grey-dark">There is nothing here</Text>
    </div>
  )
}

export default NotFound
