import { useState, useEffect } from 'react'
import Parse from 'parse'
const Server = Parse.Object.extend('Server')
const Client = Parse.Object.extend('Client')

async function _getServers ({ clientId } = {}) {
  let servers = []
  if (!clientId) return servers

  const serversQuery = new Parse.Query(Server)
  serversQuery.equalTo('client', new Client({ objectId: clientId }))
  serversQuery.notEqualTo('notBillable', true)
  serversQuery.ascending('name')
  servers = await serversQuery.find()

  return servers
}

function usePostmarkServers ({ clientId } = {}) {
  const [servers, setServers] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    let unmounting = false
    async function getServers () {
      setLoading(true)
      let servers, error
      try {
        servers = await _getServers({ clientId })
      } catch (err) {
        error = err
      }
      if (unmounting) return
      if (!error) {
        setServers(servers)
      } else {
        setError(error)
      }
      setLoading(false)
    }
    getServers()
    return () => {
      unmounting = true
    }
  }, [clientId])

  return [loading, servers, error]
}

export default usePostmarkServers
