import { useState, useEffect } from 'react'

function useActiveClient () {
  const [activeClient, setActiveClient] = useState({})

  useEffect(() => {
    setActiveClient(JSON.parse(window.localStorage.getItem('activeClient') || '{}'))
  }, [])

  // update all current instances of useActiveClient
  useEffect(() => {
    function handleEvent () {
      setActiveClient(JSON.parse(window.localStorage.getItem('activeClient') || '{}'))
    }
    window.addEventListener('updateActiveClient', handleEvent)
    return () => window.removeEventListener('updateActiveClient', handleEvent)
  }, [])

  async function update (value) {
    window.localStorage.setItem('activeClient', JSON.stringify(value))
    // update all current instances of usePaymentInterval
    window.dispatchEvent(new window.CustomEvent('updateActiveClient'))
  }

  return [activeClient, update]
}

export default useActiveClient
