import { useState, useEffect } from 'react'
import useUser from './useUser.js'
import intervalOptions from '../utils/intervalOptions.js'

function usePaymentInterval () {
  const [paymentInterval, setPaymentInterval] = useState('yearly')
  const [user, updateUser] = useUser()

  useEffect(() => {
    if (user) {
      const settings = user.get('settings') || {}
      setPaymentInterval(settings.selectedInterval || intervalOptions[0].value)
    }
  }, [user])

  // update all current instances of usePaymentInterval
  useEffect(() => {
    function handleEvent () {
      const newInterval = (user.get('settings') || {}).selectedInterval
      setPaymentInterval(newInterval)
    }
    window.addEventListener('updateUser', handleEvent)
    return () => window.removeEventListener('updateUser', handleEvent)
  }, [user])

  async function update (value) {
    if (user) {
      const settings = user.get('settings') || {}
      settings.selectedInterval = value
      await updateUser({ settings })
    }
    // update all current instances of usePaymentInterval
    window.dispatchEvent(new window.CustomEvent('updateUser'))
  }

  return [paymentInterval, update]
}

export default usePaymentInterval
